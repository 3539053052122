import React from "react";
import { useLocale, useTranslate, useDataProvider, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";

import { ListElements } from "../../Components/Containers";

export const ListView = (props) => {
    const locale = useLocale();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            label: translate("common-fields.name"),
            source: `heading.title[${locale}].auto` || "heading.title.ru.auto",
        },
        {
            type: "TextField",
            source: "slug",
        },
        {
            type: "DateField",
            source: "published_at",
        },
        {
            type: "ImageField",
            source: "picture[0].src",
        },
    ];

    const bulkActionButtons = (props) => {
        const onClick = () => {
            dataProvider
                .updateMany(props.resource, {
                    ids: props.selectedIds,
                    data: { deleted_at: moment() }
                })
                .then(refresh)
                .catch(error => console.error(error));
        };

        return (
            <Button
                size={"small"}
                onClick={onClick}
                style={{
                    color: "#f44336"
                }}
                startIcon={<DeleteIcon />}
            >
                {translate("delete")}
            </Button>
        )
    }

    return <ListElements {...props} sort={{field: "created_at", order: "DESC"}} fields={fields} BulkActionButtons={bulkActionButtons} />;
};
