import React from "react";
import {
    ArrayInput,
    SimpleFormIterator,
    useTranslate
} from "react-admin";
import PropTypes from "prop-types";

import { Picture } from "../../Fields";

export default function ContentFlow(props) {
    const { source } = props;

    const translate = useTranslate();
    
	return (
        <ArrayInput source={`${source}.media.items`} label={translate("section.content.img")}>
            <SimpleFormIterator>
                <Picture
                    source="picture"
                    style={{ width: "100%" }}
                    label={translate("picture.name")}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
}

ContentFlow.propTypes = {
	source: PropTypes.string,
};

ContentFlow.defaultProps = {
	source: "",
};
