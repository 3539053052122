import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    required,
    SelectInput,
    useTranslate,
    ReferenceInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { Heading } from "../../Components/Blocks";
import { Picture } from "../../Components/Fields";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <ReferenceInput
                                source={"original_lang_id"}
                                reference={"countries"}
                                label={translate("common-fields.original-lang")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <Heading {...props} defaultTypeValue={"h1"} />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <TextInput
                                source={"slug"}
                                label={translate("common-fields.slug")}
                                style={{ width: "100%" }}
                                resettable
                                validate={[required()]}
                            />
                            <TextInput
                                source={"sort_order"}
                                label={translate("common-fields.sort")}
                                style={{ width: "100%" }}
                                resettable
                            />
                            <Picture 
                                label={translate("common-fields.picture")}
                                source={"picture"}
                                mainImage
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}