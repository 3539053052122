import Default from "./Default";
import FacebookWidget from "./FacebookWidget";
import TwitterWidget from "./TwitterWidget";

const config = {
    "facebook-post": FacebookWidget,
    "twitter-post": TwitterWidget,
    default: Default,
    get(type) {
        return this[type] || this.default;
    },
};

export default config;
