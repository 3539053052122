import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    useTranslate
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={"slug"}
                        label={translate("common-fields.slug")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"heading.title"}
                        label={translate("common-fields.title")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"Сортировка"}
                        label={translate("common-fields.sort")}
                        style={{ width: "100%" }}
                    />
                    <ImageField source="picture[0].src" label={translate("picture.name")} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}