/* eslint-disable */
import { get } from "lodash";
import { REACT_APP_BASE_API } from "../../configs";

export default {
    // called when the user attempts to log in
    login: ({ username: login, password }) => {
        const request = new Request(
            `${REACT_APP_BASE_API}/session/auth?email=${login}&password=${password}`,
            {
                method: "GET",
                credentials: "include",
                redirect: "follow",
            }
        );

        return fetch(request)
            .then((response) => {
                const { ok, status } = response;

                if (ok) {
                    return response.json();
                }

                return { status };
            })
            .then((data) => {
                const token = get(data, "data.token");
                const refresh_token = get(data, "data.refresh_token");

                if(token) {
                    localStorage.setItem("token", token);
                    localStorage.setItem("refresh_token", refresh_token);
                } else {
                    return Promise.reject(data.status);
                }
            })
            .catch((error) => {
                console.error("Auth response catch error", { error });
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        // return Promise.resolve();
        return localStorage.getItem("token")
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
