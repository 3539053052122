import React, { useState } from "react";
import { useTranslate , useDataProvider, useRefresh} from "react-admin";
import { Button, Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

export function ActionButtons(props) {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [alert, setAlert] = useState({});
    const refresh = useRefresh();

    const { record } = props;
    const { id, state } = record;

    function enable() {
        dataProvider
            .update(props.resource, {
                id,
                data: {
                    state: "active"
                }
            })
            .then(data => {
                if(data.errors) {
                    setAlert({
                        type: "error",
                        message: translate("request-errror")
                    });

                    console.error(data.errors);
                } else {
                    setAlert({
                        type: "success",
                        message: translate("content-sources-active")
                    });

                    setTimeout(() => {
                        refresh(); 
                    }, 1000);
                    
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    function disable() {
        dataProvider
            .update(props.resource, {
                id,
                data: { 
                    state: "inactive"
                },
            })
            .then(data => {
                if(data.errors) {
                    setAlert({
                        type: "error",
                        message: translate("request-errror")
                    });

                    console.error(data.errors);
                } else {
                    setAlert({
                        type: "success",
                        message: translate("content-sources-inactive")
                    });
                    setTimeout(() => {
                        refresh(); 
                    }, 1000);
                }
            })
            .catch((error) => {
                console.error("Ошибка обновления записи: ", { error });
            });
    }

    function handleClose() {
        setAlert({});
    }

    return (
        <>
            {
                state === "inactive" ? (
                    <Button
                        variant="outlined"
                        color={"secondary"}
                        size={"small"}
                        onClick={(event) => {
                            event.stopPropagation();

                            enable();
                        }}
                    >
                        { translate("enable") }
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        color={"secondary"}
                        size={"small"}
                        onClick={(event) => {
                            event.stopPropagation();

                            disable();
                        }}
                    >
                        { translate("disable") }
                    </Button>
                )
            }
            {
                !!alert.type && (
                    <Snackbar open={!!alert.type} autoHideDuration={3000} onClose={handleClose}>
                        <Alert severity={alert.type}>
                            {alert.message}
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}