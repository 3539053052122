import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={"name"}
                        label={translate("common-fields.title")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"email"}
                        label={"email"}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"description"}
                        label={translate("common-fields.description")}
                        style={{ width: "100%" }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}