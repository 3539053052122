import React from "react";

import { CreateApi } from "../../Components/FormApi";
import Tabs from "./Tabs";

export function CreateView(props) {

    return (
        <CreateApi  {...props}>
            <Tabs {...props} />
        </CreateApi>
    );
}