import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    useTranslate,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { FileUploader } from "../../Components/Fields";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"filename"}
                                label={translate("common-fields.name")}
                                style={{width: "100%"}}
                            />
                            <TextInput
                                source={"description"}
                                label={translate("common-fields.description")}
                                style={{width: "100%"}}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <FileUploader
                                source={"url"}
                                label={translate("common-fields.url")}
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}
