import React from "react";

import { EditApi } from "../../Components/FormApi";
import Tabs from "./Tabs.js"

export function EditView(props) {
    return (
        <EditApi {...props}>
            <Tabs {...props} />
        </EditApi>
    );
}
