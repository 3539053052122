import React from "react";
import { TabbedForm, FormTab, TextInput, required, useTranslate } from "react-admin";
import Grid from "@material-ui/core/Grid";

import { CustomTextInput } from "../../Components/Fields";

export default function Tabs(props) {

    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <CustomTextInput
                                source={"name"}
                                label={translate("resources.countries.fields.name")}
                                style={{ width: "100%" }}
                                validate={[required()]}
                            />
                            <TextInput
                                source={"slug"}
                                label={translate("common-fields.slug")}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                            <TextInput
                                source={"code"}
                                label={translate("resources.countries.fields.code")}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}