import React from "react";
import { List, Datagrid, TextField, ImageField, DateField, FunctionField, BooleanField } from "react-admin";
import PropTypes from "prop-types";

export function ListElements(props) {
    const FieldsType = {
        TextField,
        ImageField,
        DateField,
        FunctionField,
        BooleanField,
    };

    const { fields = [], BulkActionButtons, children} = props;

    return (
        <List {...props} sort={{ field: "id", order: "DESC" }} bulkActionButtons={<BulkActionButtons />}>
            <Datagrid rowClick="show">
                {fields.map(({ type, ...rest }, idx) => {
                    return React.createElement(FieldsType[type], {
                        ...rest,
                        key: idx,
                    });
                })}
                {
                    React.Children.map(children, child => React.cloneElement(child, props))
                }
            </Datagrid>
        </List>
    );
}

ListElements.propTypes = {
    fields: PropTypes.array
};