
import { NumberInput, useTranslate } from "react-admin";

import { Heading } from "../../Blocks";
import ComponentType from "../../ComponentType";

export default function Default({ props, source, type }) {

    const translate = useTranslate();
    return (
        <>
            <Heading source={`${source}.heading`} defaultTypeValue={"h2"} />
            <NumberInput
                source={`${source}.sortOrder`}
                label={translate("common-fields.sort")}
            />
            <ComponentType type={type} {...props} />
        </>
    )
}