import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    required,
    BooleanInput,
    useTranslate,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

import { Heading } from "../../Components/Blocks";
import { Picture } from "../../Components/Fields";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Heading {...props} description={false} defaultTypeValue={"h1"} />
                            <BooleanInput
                                source={"main"}
                                label={translate("resources.v_partners_admin.fields.main")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.href"}
                                label={translate("resources.v_partners_admin.fields.data.href")}
                                style={{ width: "100%" }}
                                resettable
                                validate={[required()]}
                            />
                            <TextInput
                                source={"sort_order"}
                                label={translate("common-fields.sort")}
                                style={{ width: "100%" }}
                                resettable
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <Picture 
                                label={translate("common-fields.picture")}
                                source={"picture"}
                                mainImage
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}