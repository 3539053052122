import React from "react";
import { get, last } from "lodash";

import { CreateApi } from "../../Components/FormApi";
import Tabs from "./Tabs";

export function CreateView(props) {
    function transform(formData) {
        const type = {
			facebook: "ContentSource::Facebook",
			youtube: "ContentSource::YouTube",
		};

        const resourse = last(get(props, "resource", "").split("_"));

        return {
            ...formData,
            type: type[resourse]
        };
    }

    return (
        <CreateApi transform={transform} {...props} needTranslate={false}>
            <Tabs {...props} />
        </CreateApi>
    );
}