import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    useTranslate,
    ArrayInput,
    SimpleFormIterator
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"source.name"}
                                label={translate("common-fields.source.name")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"source.fullName"}
                                label={translate("common-fields.source.fullName")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"source.url"}
                                label={translate("common-fields.sourceUrl")}
                                style={{ width: "100%" }}
                            />
                            <ReferenceInput
                                source={"country_id"}
                                reference={"v_news_countries"}
                                label={translate("common-fields.country")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <ReferenceInput
                                source={"original_lang_id"}
                                reference={"countries"}
                                label={translate("common-fields.original-lang")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <SelectInput
                                source={"cron"}
                                label={translate("common-fields.cron")}
                                style={{
                                    width: "100%",
                                }}
                                defaultValue={"base"}
                                choices={[
                                    {
                                        id: "0 * * * *",
                                        name: translate("common-fields.cron.hour"),
                                    },
                                    {
                                        id: "0 0 * * *",
                                        name: translate("common-fields.cron.day"),
                                    },
                                    {
                                        id: "0 0 * * 6",
                                        name: translate("common-fields.cron.week"),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            {props.children}
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <FormTab label={translate("tabs.rssList")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"data.listSource"}
                                label={translate("common-fields.data.listSource")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.publishedAt"}
                                label={translate("common-fields.data.publichedxpath")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.url"}
                                label={translate("common-fields.data.newsxpath")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.teaser"}
                                label={translate("common-fields.teaser")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.picture"}
                                label={translate("common-fields.picture")}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <FormTab label={translate("tabs.parserSettings")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"data.title"}
                                label={translate("common-fields.title")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.category"}
                                label={translate("common-fields.category")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.mainPicture"}
                                label={translate("common-fields.picture")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.contentTitle"}
                                label={translate("common-fields.contentTitle")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.contentDescription"}
                                label={translate("common-fields.contentDescription")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.contentPicture"}
                                label={translate("common-fields.contentPicture")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.contentPictureGallery"}
                                label={translate("common-fields.contentPictureGallery")}
                                style={{ width: "100%" }}
                            />

                            <ArrayInput source={"data.xpath"} label={"xpath"}>
                                <SimpleFormIterator>
                                    <TextInput
                                        source={"name"}
                                        label={translate("common-fields.name")}
                                        style={{ width: "100%" }}
                                    />
                                    <TextInput
                                        source={"xpath"}
                                        label={"xpath"}
                                        style={{ width: "100%" }}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}
