import React from "react";
import {
    Create,
    ReferenceInput,
    TabbedShowLayout,
    Tab,
    SimpleForm,
    required,
    TextField,
    ReferenceField,
    Show,
    Edit,
    useTranslate,
    useLocale
} from "react-admin";
import { get } from "lodash";

import { Autocomplete } from "../../Components/Fields";

export const CreateView = (props) => {
    const translate = useTranslate();

    const redirect = get(props, "location.state.redirect_to", "") || false;

    return (
        <Create {...props}>
            <SimpleForm redirect={redirect}>
                <ReferenceInput
                    label={translate("resources.content_connects.name")}
                    source="linked_content_id"
                    reference="v_news_admin"
                    perPage={1000}
                >
                    <Autocomplete title={translate("resources.content_connects.name")} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};

export const EditView = (props) => {
    const translate = useTranslate();

    return (
        <Edit {...props}>
            <SimpleForm>
                <ReferenceInput
                    label={translate("resources.content_connects.name")}
                    source="linked_content_id"
                    reference="v_news_admin"
                    validate={required()}
                    perPage={1000}
                    sort={{
                        field: "id",
                        order: "desc",
                    }}
                >
                    <Autocomplete
                        resource="v_news_admin"
                        source={"linked_content_id"}
                        title={translate("resources.content_connects.name")}
                    />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};

export const ShowView = (props) => {
    const locale = useLocale();
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <ReferenceField
                        label={translate("resources.content_connects.name")}
                        source="linked_content_id"
                        reference="news"
                        perPage={1000}
                        sort={{
                            field: "id",
                            order: "desc",
                        }}
                    >
                        <TextField source={`heading.title[${locale}.auto]`} />
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
