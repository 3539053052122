import { REACT_APP_BASE_API } from "../configs";

export * from "./prepareLogData";
export * from "./translater";

export async function loadData(url = "", options = {}) {
    const option = Object.assign({}, options);
    delete option.headers;

    const requestOptions = Object.assign(
        {},
        {
            method: "GET",
            credentials: "include",
            redirect: "follow",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                ...options.headers
            }
        },
        option
    );

    const response = await fetch(url, requestOptions);

    switch (response.status) {
        case 404:
            return Promise.reject(response);
        case 401:
            return refreshToken(loadData, url, options);
        case 403:
            return Promise.reject(response);
        case 500:
            return Promise.reject({ message: "Ошибка сервера" });
        case 200:
            try {
                return await response.json();
            } catch {
                return Promise.resolve(response);
            }
        default:
            return await response.json();
    }
}

export async function refreshToken(callback, url, options) {
    localStorage.removeItem("token");

    const response = await fetch(`${REACT_APP_BASE_API}/session/auth`, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("refresh_token")}`
            }
        }
    );

    let data;

    try {
        localStorage.removeItem("refresh_token");

        data = await response.json();
    } catch {
        return Promise.resolve(response);
    }

    if(data) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("refresh_token", data.data.refresh_token);

        if(callback) {
            return callback(url, options);
        }
    }
}