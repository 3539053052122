import React from "react";

import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField source={"filename"} label={translate("common-fields.name")} style={{ width: "100%" }}/>
                    <TextField source={"description"} label={translate("common-fields.description")} />
                    <TextField source={"url"} label={translate("common-fields.url")} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
