import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import ContentFlow from "./ContentFlow";
import ImageGallery from "./ImageGallery";
import About from "./About";
import AboutPersons from "./AboutPersons";
import Accordion from "./Accordion";

import "./style.scss";

function Default() {
	return null;
}

const components = {
    Default,
    "content-flow": ContentFlow,
    "accordion": Accordion,
    "about": About,
    "image-gallery": ImageGallery,
    "split-news": Default,
	"about-persons": AboutPersons
};

export default function ComponentType(props) {
	const { record, type } = props;
	const [componentType, setComponentType] = useState();

	useEffect(() => {
		if (type) {
			setComponentType(type);
		}
	}, [type, record]);

	return React.createElement(components[componentType] || components.Default, props);
}

ComponentType.displayName = "ComponentType";

ComponentType.propTypes = {
	record: PropTypes.object,
	type: PropTypes.string,
};

ComponentType.propTypes = {
	record: {},
	type: "Default",
};
