/* eslint-disable */

import React, { useEffect, useReducer } from "react";
import { addField } from "ra-core";
import { showNotification, useTranslate } from "react-admin";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";
import { DeleteForever, Image, Crop } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dropzone from "react-dropzone-uploader";
import imageCompression from "browser-image-compression";
import Cropper from "react-cropper";

import { get, last } from "lodash";

import { REACT_APP_S3_API_URL } from "../../../configs";
import { loadData } from "../../../utils/index";

import "react-dropzone-uploader/dist/styles.css";
import "cropperjs/dist/cropper.css";

import "./uploaderStyles.scss";

function InputS3Uploader({
        input,
        label = "",
        style = {},
        needCrop = false,
        forceCrop = false,
        compressOptions = {},
        saveWithSizes = false,
    }) {
    const reduxDispatch = useDispatch();
    const translate = useTranslate();

    let initialImg = { src: get(input, "value.src") || get(input, "value") };

    const STATE = {
        init: {
            showCrop: false,
            showDownload: true,
            showCropButton: false,
            showUploadedImg: false,
        },
        existImg: {
            showCrop: false,
            showDownload: false,
            showCropButton: true,
            showUploadedImg: true,
        },
        onUploadImg: {
            showCrop: true,
            showDownload: false,
            showCropButton: false,
            showUploadedImg: false,
        },
        onCropImg: {
            showDownload: false,
            showCropButton: false,
            showUploadedImg: false,
        },
    };

    const reducer = (state, action) => {
        const { type, payload } = action;

        switch (type) {
            case "INIT":
                return {
                    ...state,
                    img: payload,
                    appState: STATE.existImg,
                };
            case "SET_CROPER":
                return {
                    ...state,
                    cropper: payload,
                };
            case "CROP_MODE":
                return {
                    ...state,
                    appState: {
                        ...state.appState,
                        showCrop: true,
                        showCropButton: false,
                    },
                };
            case "CROP_IMG":
                return {
                    ...state,
                    img: {
                        ...state.img,
                        cropData: payload,
                    },
                    appState: STATE.onCropImg,
                    saveToS3: true,
                    loading: true,
                };
            case "ON_UPLOLOAD_IMG":
                return {
                    ...state,
                    img: payload,
                    appState: STATE.onUploadImg,
                };
            case "SAVE_WITHOUT_CROP":
                return {
                    ...state,
                    img: payload,
                    saveToS3: true,
                    loading: true,
                };
            case "UPLOADING":
                return {
                    ...state,
                    saveToS3: true,
                    loading: true,
                };
            case "S3":
                return {
                    ...state,
                    s3Path: payload,
                };
            case "COMPRESS":
                return {
                    ...state,
                    img: {
                        ...state.img,
                        compressedData: payload,
                    },
                    compressed: true,
                };
            case "DELETE_IMG":
                return {
                    ...state,
                    img: {},
                    appState: STATE.init,
                    compressed: false,
                };
            case "FINISHED":
                return {
                    ...state,
                    img: {
                        ...state.img,
                        src: payload,
                    },
                    appState: {
                        ...STATE.existImg,
                        finished: true,
                    },
                    saveToS3: false,
                    loading: false,
                };
            case "SET_SIZES": {
                return {
                    ...state,
                    img: {
                        ...state.img,
                        ...payload,
                    },
                    appState: {
                        ...STATE.existImg,
                        sized: true,
                    },
                };
            }
            default:
                return state;
        }
    };

    const initialState = () => ({
        img: { ...initialImg },
        appState: STATE.init,
        s3Path: {},
        saveToS3: false,
        compressed: false,
        loading: false,
    });

    const [state, dispatch] = useReducer(reducer, {}, initialState);

    const {
        img,
        cropper,
        s3Path,
        saveToS3,
        compressed,
        loading,
        appState: {
            showCrop,
            showDownload,
            showCropButton,
            showUploadedImg,
            finished = false,
            sized = false,
        } = STATE.init,
    } = state;

    useEffect(() => {
        if (initialImg.src) {
            dispatch({
                type: "INIT",
                payload: initialImg,
            });
            initialImg = null;
        }
    }, []);

    useEffect(() => {
        if (saveToS3) {
            getS3Path();
        }
    }, [saveToS3]);

    useEffect(() => {
        if (s3Path.path) {
            compressImg();
        }
    }, [s3Path]);

    useEffect(() => {
        putToS3();
    }, [compressed]);

    useEffect(() => {
        if (saveWithSizes && sized) {
            const onChange = get(input, "onChange", () => Function);

            const newImg = {
                src: img.src,
                width: img.width,
                height: img.height,
            };

            onChange(newImg);
        }
    }, [sized]);

    function handleDelete() {
        const onChange = get(input, "onChange", () => Function);
        onChange("");
        dispatch({ type: "DELETE_IMG" });
    }

    function onChangeStatus({ meta }) {
        if (meta.previewUrl && !get(img, "src", "")) {
            const payload = {
                src: get(meta, "previewUrl", ""),
                objectName: encodeURIComponent(
                    get(meta, "name", "")
                        .replace(/[\s]+/gi, "_")
                        .replace(/[^\wа-яА-ЯёЁ\d_\-\.]+/gi, "")
                ),
                contentType: get(meta, "type", ""),
                size: get(meta, "size", 0),
                loaded: true,
            };

            if (needCrop) {
                dispatch({
                    type: "ON_UPLOLOAD_IMG",
                    payload,
                });
            } else {
                dispatch({
                    type: "SAVE_WITHOUT_CROP",
                    payload,
                });
            }
        }
    }

    function getCropData(e) {
        e.preventDefault();

        if (typeof cropper !== "undefined") {
            dispatch({
                type: "CROP_IMG",
                payload: cropper.getCroppedCanvas().toDataURL(),
            });
        }
    }

    function blobToBase64(blob) {
        const reader = new FileReader();

        reader.readAsDataURL(blob);

        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(get(reader, "result", ""));
            };
        });
    }

    function getS3Path() {
        reduxDispatch(showNotification(translate("picture.loading")));

        if (s3Path.path) {
            putToS3();

            return;
        }

        const options = {
            headers: {
                "x-amz-acl": "public-read",
                "Cache-Control": "max-age=31536000"
            }
        }

        const url = `${REACT_APP_S3_API_URL}?objectName=${get(img, "objectName", last(get(img, "src", "").split("/")))}&contentType=${get(img, "contentType", "image/jpeg")}&kind=serafim-tours-uploads&path=content`;

        loadData(url, options)
            .then((data) =>
                dispatch({
                    type: "S3",
                    payload: {
                        path: get(data, "signedUrl", ""),
                    },
                })
            )
            .catch((error) => {
                console.error("Error:" + error);
            });
    }

    function putToS3() {
        const data = get(
            img,
            "compressedData",
            get(img, "cropData", get(img, "src", ""))
        );

        fetch(data)
            .then((result) => {
                result.arrayBuffer().then((buffer) => {
                    const type = get(img, "contentType", "image/jpeg");

                    const body = new File([buffer], "image_data_url.jpg", {
                        type,
                    });

                    const headers = new Headers();

                    headers.append("x-amz-acl", "public-read");
                    headers.append("Cache-Control", "no-cache");
                    headers.append("Content-Type", type);
                    headers.append(
                        "Content-Disposition",
                        `inline; filename="${get(
                            img,
                            "objectName",
                            last(get(img, "src", "").split("/"))
                        )}"`
                    );

                    const request = new Request(s3Path.path, {
                        headers,
                        method: "PUT",
                        body,
                    });

                    fetch(request)
                        .then(
                            (response) =>
                                !!get(response, "ok", false) && response
                        )
                        .then(() => {
                            const { onChange = () => {} } = input;

                            const src = get(s3Path.path.split("?"), "[0]", "");

                            onChange(src);

                            dispatch({
                                type: "FINISHED",
                                payload: src,
                            });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    function compressImg() {
        if (s3Path.path) {
            const options = {
                maxSizeMB: 1,
                useWebWorker: true,
                ...compressOptions,
            };

            const src = get(img, "cropData", get(img, "src", ""));

            if (get(img, "size", 1000000) > 40000) {
                fetch(src)
                    .then((result) => result.blob())
                    .then(async (blob) => {
                        console.log(
                            `originalFile size ${blob.size / 1024 / 1024} MB`
                        );

                        // IMAGE COMPRESSOR
                        const compressedFile = await imageCompression(
                            blob,
                            options
                        );
                        console.log("img compressed");

                        console.log(
                            `compressedFile size ${
                                compressedFile.size / 1024 / 1024
                            } MB`
                        ); // smaller than maxSizeMB

                        blobToBase64(compressedFile).then((base64) => {
                            dispatch({
                                type: "COMPRESS",
                                payload: base64,
                            });
                        });
                    });
            } else {
                dispatch({ type: "COMPRESS" });
            }
        }
    }

    return (
        <div
            className={"drug-crop-uploader"}
            style={Object.assign(
                {},
                {
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                },
                style
            )}
        >
            <h3 style={{ textAlign: "left", marginRight: 8 }}>{label}</h3>
            {needCrop && showCrop && (
                <div>
                    <div style={{ width: "100%" }}>
                        <Cropper
                            style={{ height: 400, width: "100%" }}
                            initialAspectRatio={16 / 9}
                            aspectRatio={16 / 9}
                            src={get(img, "src", "")}
                            viewMode={1}
                            guides={true}
                            minCropBoxHeight={10}
                            minCropBoxWidth={20}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                dispatch({
                                    type: "SET_CROPER",
                                    payload: instance,
                                });
                            }}
                        />
                    </div>
                    <div>
                        <div className="cropped-img">
                            <Button
                                onClick={getCropData}
                                variant={"contained"}
                                color={"primary"}
                                style={{
                                    width: "100%",
                                    marginTop: 16,
                                    marginBottom: 16,
                                }}
                            >
                                <Crop style={{ marginRight: ".5rem" }} />
                                {translate("picture.crop")}
                            </Button>
                            {!forceCrop && (
                                <Button
                                    onClick={() => dispatch({ type: "UPLOADING" })}
                                    variant={"contained"}
                                    color={"default"}
                                    style={{
                                        width: "100%",
                                        marginTop: 16,
                                        marginBottom: 16,
                                    }}
                                >
                                    {translate("picture.set-original")}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {showDownload && (
                <Dropzone
                    onChangeStatus={onChangeStatus}
                    accept=".png, .jpg, .jpeg, .webp"
                    multiple={false}
                    inputContent={
                        <div className={"dropzone"}>
                            <Image style={{ marginRight: ".5rem" }} />
                            {translate("picture.download")}
                        </div>
                    }
                />
            )}
            {needCrop && showCropButton && (
                <Button
                    onClick={() =>
                        dispatch({
                            type: "CROP_MODE",
                        })
                    }
                    variant={"contained"}
                    color={"primary"}
                    style={{
                        width: "100%",
                        marginBottom: 16,
                    }}
                >
                    <Crop style={{ marginRight: ".5rem" }} />
                    {translate("picture.crop")}
                </Button>
            )}
            {showUploadedImg && (
                <>
                    <Button
                        variant={"contained"}
                        color={"default"}
                        style={{
                            width: "100%",
                            marginBottom: 16,
                        }}
                        onClick={handleDelete}
                    >
                        <DeleteForever style={{ marginRight: ".5rem" }} />
                        {translate("ra.action.delete")}
                    </Button>
                    <div className={"uploader__file"} style={{}}>
                        <img
                            id={get(img, "objectName", "").split(".")[0]}
                            src={get(img, "src", "")}
                            alt={get(img, "alt", "")}
                            style={{ width: "100%" }}
                            onLoad={(event) => {
                                finished &&
                                    dispatch({
                                        type: "SET_SIZES",
                                        payload: {
                                            width: event.target.naturalWidth,
                                            height: event.target.naturalHeight,
                                        },
                                    });
                            }}
                            onError={(error) => console.error(error)}
                        />
                    </div>
                </>
            )}
            {loading && <CircularProgress />}
        </div>
    );
}

export const InputS3UploaderField = addField(InputS3Uploader);
