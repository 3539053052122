import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
    useLocale,
    ImageField,
    BooleanField
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    const locale = useLocale();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={`heading.title[${locale}].auto` || "heading.title.ru.auto"}
                        label={translate("common-fields.title")}
                        style={{ width: "100%" }}
                    />
                    <BooleanField
                        source={"main"}
                        label={translate("resources.v_partners_admin.fields.main")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"data.href"}
                        label={translate("resources.v_partners_admin.fields.data.href")}
                        style={{ width: "100%" }}
                    />
                    <ImageField source="picture[0].src" label={translate("picture.name")} />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}