
import get from "lodash/get";
import { loadData } from "./index";

import { REACT_APP_TRANSLATE_API_URL } from "../configs";

const localesMap = {
    ru: "ru",
    en: "en",
    el: "el",
    sr: "sr",
    fr: "fr",
    ge: "ka",
    al: "sq",
    ae: "ar",
    he: "he",
    tr: "tr",
    cn: "zh",
    ja: "ja",
};

const localesMapInvert = {
    ru: "ru",
    en: "en",
    el: "el",
    sr: "sr",
    fr: "fr",
    ka: "ge",
    sq: "al",
    ar: "ae",
    he: "he",
    tr: "tr",
    zh: "cn",
    ja: "ja",
};

export async function translater(texts = "", locales = []) {
    if (!texts) {
        return Promise.resolve([])
    }
    
    const langs = locales.map((lang) => localesMap[lang]);

    const requestOptions = {
        method: "POST",
        body: JSON.stringify({
            langs,
            texts
        }),
    };

    return loadData(REACT_APP_TRANSLATE_API_URL, requestOptions)
        .then(data => {
            return (
                Promise.resolve(
                    Object.entries(get(data, "data", {}))
                        .map(
                            ([lang, translations]) => ({
                                [localesMapInvert[lang]]: get(translations, "translations[0].text", "")
                            })
                        )
                )
            )
        })
        .catch (error => console.log(error));
}