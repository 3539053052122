import React, { useEffect, useState } from "react";
import { addField } from "ra-core";
import { Tweet } from 'react-twitter-widgets';
import TextField from '@material-ui/core/TextField';

function CustomInput({ input: { onChange, value } }) {
    const [tweetId, setTweetId] = useState(null)

    useEffect(() => {
        if (value.url) {
            const array = value.url.split("/");

            setTweetId(array[array.length - 1]);
        } else {
            setTweetId(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TextField
                        id="filled-basic"
                        label="Ссылка на пост из Twitter"
                        variant="filled"
                        name="Twitter"
                        onChange={(event) => onChange({
                            url: event.target.value
                        })}
                        style={{ width: "96%" }}
                        value={value.url}
                    />
                </div>
                <div style={{ flex: "1" }}>
                    {
                        tweetId && (
                            <Tweet tweetId={tweetId} />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export const TwitterInput  = addField(CustomInput);
