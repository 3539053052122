import React from "react";
import {
    ArrayInput,
    SimpleFormIterator,
    useTranslate
} from "react-admin";
import PropTypes from "prop-types";

import { CustomTextInput, CustomRichText } from "../../Fields";
export default function Accordion(props) {
    const { source } = props;

    const translate = useTranslate();
    
	return (
        <ArrayInput source={`${source}.items`} label={translate("section.content.accordion")}>
            <SimpleFormIterator>
                <CustomTextInput
                    label={translate("common-fields.heading")}
                    source={"title"}
                />
                <CustomRichText
                    label={translate("common-fields.description")}
                    source={"content"}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
}

Accordion.propTypes = {
	source: PropTypes.string,
};

Accordion.defaultProps = {
	source: "",
};
