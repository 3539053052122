import React, { useState, useEffect } from "react";
import { addField } from "ra-core";
import TextField from "@material-ui/core/TextField";
import { EmbeddedPost } from "react-facebook";

function CustomInput({ input: { onChange, value } }) {
    const [url, setUrl] = useState(value);

    function handleChange(event) {
        setUrl(event.target.value);
    }

    useEffect(() => {
        onChange(url);
    }, [onChange, url]);

    return (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div style={{ width: "100%", display: "flex" }}>
                <div style={{ flex: "1" }}>
                    <TextField
                        label="Ссылка на пост из Facebook"
                        variant="filled"
                        name="Facebook"
                        id="faceBookSrcPost"
                        onChange={handleChange}
                        style={{ width: "96%" }}
                        value={value}
                    />
                </div>
                <div style={{ flex: "1" }}>
                    {url && <EmbeddedPost href={url} />}
                </div>
            </div>
        </div>
    );
}

export const FaceBookInput = addField(CustomInput);