import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={"name"}
                        label={translate("common-fields.title")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"slug"}
                        label={translate("common-fields.slug")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"code"}
                        label={translate("resources.countries.fields.code")}
                        style={{ width: "100%" }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}