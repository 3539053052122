import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    useTranslate,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"source.name"}
                                label={translate("common-fields.source.name")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"source.fullName"}
                                label={translate("common-fields.source.fullName")}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"source.url"}
                                label={translate("common-fields.sourceUrl")}
                                style={{ width: "100%" }}
                            />
                            <ReferenceInput
                                source={"country_id"}
                                reference={"v_news_countries"}
                                label={translate("common-fields.country")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <ReferenceInput
                                source={"original_lang_id"}
                                reference={"countries"}
                                label={translate("common-fields.original-lang")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <SelectInput
                                source={"cron"}
                                label={translate("common-fields.cron")}
                                style={{
                                    width: "100%",
                                }}
                                defaultValue={"base"}
                                choices={[
                                    {
                                        id: "0 * * * *",
                                        name: translate("common-fields.cron.hour"),
                                    },
                                    {
                                        id: "0 0 * * *",
                                        name: translate("common-fields.cron.day"),
                                    },
                                    {
                                        id: "0 0 * * 6",
                                        name: translate("common-fields.cron.week"),
                                    },
                                ]}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            {props.children}
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}
