import React, { useState } from "react";
import PropTypes from "prop-types";

import { TranslateProvider } from "../Context";

export default function Provider(props) {

    const [locale, setLocale] = useState("ru");
    const [translateState, setTranslateState] = useState({
        ru: true,
        en: true,
        el: true,
        sr: true,
        fr: true,
        ge: true,
        al: true,
        ae: true,
        he: true,
        tr: true,
        cn: true,
        ja: true,
    });

    return (
        <TranslateProvider
            value={{
                locale,
                handleChangeLocale: setLocale,
                translateState,
                handleSetTranslateState: setTranslateState,
            }}
        >
            {props.children}
        </TranslateProvider>
    );
}

Provider.propTypes = {
    ssrClient: PropTypes.bool,
    children: PropTypes.array,
};

Provider.defaultProps = {
    ssrClient: false,
    children: [],
};
