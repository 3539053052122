import React, { useState } from "react";
import { useTranslate , useDataProvider, useRefresh, BulkDeleteButton } from "react-admin";
import { Button, Snackbar } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import { ListElements } from "../../Components/Containers";

export function ListView(props) {
    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            source: "name" ,
        },
        {
            type: "TextField",
            source: "email",
        },
        {
            type: "TextField",
            source: "state",
        },
    ];

    const BulkActionButtons = ({ basePath }) => <BulkDeleteButton basePath={basePath} />;

    return (
        <ListElements {...props} fields={fields} BulkActionButtons={BulkActionButtons}>
            <ActionButtons />
        </ListElements>
    );
};

function ActionButtons(props) {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [error, setError] = useState({});
    const refresh = useRefresh();

    const { record } = props;
    const { id, state } = record;

    function approve() {
        fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/v1/graphql`, {
            method: "POST",
            credentials: "include",
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                query: "mutation MyMutation($id: Int!) { createAuthor(authorRequestId: $id) }",
                variables: {
                    id
                }
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.errors) {
                setError({
                    type: "error",
                    message: translate("resources.author_requests.request-errror")
                });

                console.error(data.errors);
            } else {
                setError({
                    type: "success",
                    message: translate("resources.author_requests.approve-success")
                });

                setTimeout(() => {
                    refresh(); 
                }, 1000);
                
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    function reject() {
        dataProvider
            .update("author_requests", {
                id,
                data: { 
                    state: "rejected"
                },
            })
            .then(data => {
                if(data.errors) {
                    setError({
                        type: "error",
                        message: translate("resources.author_requests.request-errror")
                    });

                    console.error(data.errors);
                } else {
                    setError({
                        type: "success",
                        message: translate("resources.author_requests.reject-success")
                    });
                    setTimeout(() => {
                        refresh(); 
                    }, 1000);
                }
            })
            .catch((error) => {
                console.error("Ошибка обновления записи: ", { error });
            });
    }

    function handleClose() {
        setError({});
    }

    return (
        <>
            {
                state === "created" && (
                    <> 
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            size={"small"}
                            onClick={(event) => {
                                event.stopPropagation();

                                reject();
                            }}
                        >
                            { translate("resources.author_requests.reject") }
                        </Button>
                        {` `}
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            size={"small"}
                            onClick={(event) => {
                                event.stopPropagation();

                                approve();
                            }}
                        >
                            { translate("resources.author_requests.approve") }
                        </Button>
                    </>
                )
            }
            {
                !!error.type && (
                    <Snackbar open={!!error.type} autoHideDuration={3000} onClose={handleClose}>
                        <Alert severity={error.type}>
                            {error.message}
                        </Alert>
                    </Snackbar>
                )
            }
        </>
    )
}