/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocale } from "react-admin";
import { addField } from "ra-core";
import Select from "react-select";
import get from "lodash/get";

import "./autocomplete.scss";

function AutocompleteField(props) {
    const { input, choices, title = "Выберите значение" } = props;
    const { value = "", onChange = () => Function } = input;

    const locale = useLocale();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        const choicesOptions = choices.map((item) => ({
            value: get(item, "id", ""),
            label: get(item, `heading.title[${locale}.auto]`) || get(item, "title_full", ""),
        }))

        setOptions(choicesOptions);
    }, [choices]);

    let defaultValue;

    options.forEach((item, idx) => {
        if (+get(item, "value", 0) === +value) {
            defaultValue = idx;
            return;
        }
    });

    return (
        options.length > 1 && (
            <>
                <p>{title}</p>
                <Select
                    className="autocomplete-select"
                    defaultValue={options[defaultValue]}
                    isClearable={true}
                    isSearchable={true}
                    name={"name"}
                    options={options}
                    onChange={(event) => onChange(get(event, "value"))}
                />
            </>
        )
    );
}

export const Autocomplete = addField(AutocompleteField);
