import { createContext, useContext } from "react";

export const TranslateContext = createContext({
    locale: "",
    translateState: {},
    handleChangeLocale: () => Function,
    handleSetTranslateState: () => Function
});

export function useTranslate() {
    return useContext(
        TranslateContext
    )
}
export const TranslateProvider = TranslateContext.Provider;

export default TranslateContext;
