import React from "react";
import { useTranslate } from "react-admin";

import { Heading } from "../../Blocks";
import { CustomRichText } from "../../Fields";

export default function ImageGallery({source}) {
    const translate = useTranslate();

    return (
        <>
            <Heading source={`${source}.content.heading`}/>
            <CustomRichText 
                source={`${source}.content.text`}
                label={translate("section.about.body")}
                style={{ width: "100%" }}
            />
        </>
    );
}