import React from "react";
import { Admin, Resource } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { createMuiTheme } from "@material-ui/core/styles";
import { FacebookProvider } from "react-facebook";

import {
    Settings as SettingsIcon,
    // FiberNew as NewsIcon,
    ImportContacts as ArticleIcon,
    Language as LanguageIcon,
    Category as CategoryIcon,
    RecordVoiceOver as Moderator,
    Person as PersonIcon,
    Dashboard as Projects,
    BlurCircular,
    RssFeed,
    DynamicFeed,
    Facebook,
    Subscriptions,
    SupervisedUserCircle,
    People,
    AttachFile
} from "@material-ui/icons";

import { AuthProvider, DataProvider } from "./providers";
import { TranslateProvider } from "./widgets";
import Layout from "./Components/Layout";
import * as Models from "./Models";
import messages from "./i18n";
import { REACT_APP_FACEBOOK_API_KEY } from "./configs";

import "./App.css";

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ru");

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#20274A",
            contrastText: "#fff",
            dark: "#3f50b5",
        },
        secondary: {
            main: "#D49967",
        },
    },
});

function App() {
    function resourceProps(table) {
        return {
            list: table.ListView,
            create: table.CreateView,
            edit: table.EditView,
            show: table.ShowView,
        };
    }

    return (
        <TranslateProvider>
            <FacebookProvider appId={REACT_APP_FACEBOOK_API_KEY}>
                <Admin
                    locale={"ru"}
                    theme={theme}
                    authProvider={AuthProvider}
                    dataProvider={DataProvider}
                    i18nProvider={i18nProvider}
                    layout={Layout}
                >
                    <Resource
                        name="settings"
                        icon={SettingsIcon}
                        {...resourceProps(Models.Settings)}
                    />

                    {/* <Resource
                        name="v_news_admin"
                        icon={NewsIcon}
                        {...resourceProps(Models.Contents)}
                    /> */}
                    <Resource
                        name="v_text_pages"
                        icon={ArticleIcon}
                        {...resourceProps(Models.Contents)}
                    />

                    <Resource
                        name="v_projects_admin"
                        icon={Projects}
                        {...resourceProps(Models.Contents)}
                    />

                    <Resource
                        name="countries"
                        icon={LanguageIcon}
                        {...resourceProps(Models.Countries)}
                    />

                    <Resource
                        name="categories"
                        icon={CategoryIcon}
                        {...resourceProps(Models.Categories)}
                    />
                    <Resource
                        name="linked_contents"
                        {...resourceProps(Models.ContentConnects)}
                    />
                    <Resource
                        name="v_moderators_admin"
                        icon={Moderator}
                        {...resourceProps(Models.Moderators)}
                    />
                    <Resource
                        name="author_requests"
                        icon={PersonIcon}
                        {...resourceProps(Models.Authors)}
                    />
                    <Resource
                        name="content_countries"
                        icon={BlurCircular}
                        {...resourceProps(Models.ContentCountries)}
                    />
                    <Resource name="v_news" />
                    <Resource name="v_news_countries" />
                    <Resource name="v_project_countries" />
                    <Resource name="v_content_sources_rss" 
                        icon={RssFeed}
                        {...resourceProps(Models.ContentSourcesRss)}
                    />
                    <Resource name="v_content_sources_norss" 
                        icon={DynamicFeed}
                        {...resourceProps(Models.ContentSources)}
                    />
                    <Resource name="v_content_sources_facebook" 
                        icon={Facebook}
                        {...resourceProps(Models.ContentSourcesSocial)}
                    />
                    <Resource name="v_content_sources_youtube" 
                        icon={Subscriptions}
                        {...resourceProps(Models.ContentSourcesSocial)}
                    />
                    <Resource name="subscribers" 
                        icon={SupervisedUserCircle}
                        {...resourceProps(Models.Subscribers)}
                    />
                    <Resource name="v_partners_admin" 
                        icon={People}
                        {...resourceProps(Models.Partners)}
                    />
                    <Resource 
                        name={"files"}
                        icon={AttachFile}
                        {...resourceProps(Models.Files)}
                    />
                </Admin>
            </FacebookProvider>
        </TranslateProvider>
    );
}

export default App;
