import React from "react";
import {
    ArrayInput,
    SimpleFormIterator,
    useTranslate
} from "react-admin";
import PropTypes from "prop-types";

import { CustomTextInput, InputS3UploaderField } from "../../Fields";

export default function ImageGallery({ source }) {
    const translate = useTranslate();

    return (
        <>
            <ArrayInput
                label={translate("section.image-gallery.name")}
                source={`${source}.images`}
                style={{
                    paddingLeft: 8,
                }}
            >
                <SimpleFormIterator>
                    <CustomTextInput label={translate("section.image-gallery.title")} source={"title"} />
                    <Images />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    );
}

function Images({ source }) {
    const translate = useTranslate();

    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                marginBottom: "1rem",
                justifyContent: "space-between",
            }}
        >
            <InputS3UploaderField
                source={`${source}.original`}
                label={translate("picture.name")}
                style={{ width: "45%" }}
                // saveWithSizes={true}
                needCrop
                forceCrop
            />
        </div>
    );
}

ImageGallery.propTypes = {
    source: PropTypes.string,
};

ImageGallery.defaultProps = {
    source: "",
};
