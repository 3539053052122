/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { addField } from "ra-core";
import get from "lodash/get";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { useTranslate } from "react-admin";

import Button from "@material-ui/core/Button";
import { useToggle } from "@umijs/hooks";
import TranslateIcon from "@material-ui/icons/Translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useTranslate as useTranslateWidget } from "../../../widgets";
import { translate as translateApi } from "./helpers";

const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
});

const RichInput = (props) => {
    const { input, label } = props;

    const { onChange } = input;

    const translate = useTranslate();
    const { locale, translateState } = useTranslateWidget();

    const { state: tab, toggle: toggleTab } = useToggle("write", "preview");

    const [value, setValue] = useState(
        get(input, `value[${locale}].manual`, "")
    );

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const localesToTranslate = Object.entries(translateState).reduce(
        (locales, [key, val]) => {
            val && locales.push(key);
            return locales;
        },
        []
    );

    function setInputValue(val) {
        const data = {
            ...input.value,
            [locale]: {
                ...input.value[locale],
                manual: val,
            },
        };

        onChange(data);
    };

    function translateText() {
        setLoading(true);
        translateApi(value, localesToTranslate, locale, input)
            .then((data) => {
                setLoading(false);
                onChange(data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setOpen(true);
            });
    }

    function handleClose(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    }

    return (
        <div
            style={{
                border: "1px solid #ccc",
                padding: "10px",
                margin: "10px 0",
                borderRadius: "5px",
            }}
        >
            <h4 style={{
                    margin: "10px 0"
                }}
            >
                {label}
            </h4>
            <ReactMde
                value={value}
                onChange={(val) => {
                    setInputValue(val);
                    setValue(val);
                }}
                selectedTab={tab}
                onTabChange={toggleTab}
                generateMarkdownPreview={(markdown) =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
            />
            <div style={{ width: "100%", marginBottom: 10 }}>
            <ReactMde
                key="auto"
                value={get(input, `value[${locale}].auto`, "")}
                label={`${label}: (auto)`}
                readOnly={true}
            />
            </div>
            <Button variant="outlined" onClick={translateText}>
                <span style={{ marginRight: 8 }}>{translate("translate")}</span>
                <TranslateIcon size={12} />
                {loading && <CircularProgress size={20} />}
            </Button>
            <Button
                variant="outlined"
                style={{marginLeft: 10}}
                onClick={() => {
                    onChange({});
                    setValue("");
                }}
            >
                <span>{translate("clear")}</span>
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={translate("translating-error")}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </div>
    );
};

export const CustomRichText = addField(RichInput);

RichInput.propTypes = {
    input: PropTypes.object,
};
RichInput.defaultProps = {
    input: {},
};
