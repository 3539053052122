import React from "react";
import get from "lodash/get";

import { CreateApi } from "../../Components/FormApi";
import Tabs from "./Tabs";
import { useTranslate } from "../../widgets";
import { translater } from "../../utils";

export function CreateView(props) {
    const { translateState } = useTranslate();

    async function transform(formData) {
        const type = {
			news: "Content::News",
			text: "Content::TextPage",
			projects: "Content::Project",
		};

        const resourse = get(get(props, "resource", "").split("_"), "[1]", "");

        const locales = Object.entries(translateState).reduce((locales, [key, val]) => {
                val && locales.push(key);
                return locales;
            },
            []
        );

        const heading = get(Object.entries(get(formData, "heading.title", {})), "[0][1].manual");

        const translatedSlug = heading && await translater(heading, ["en"]);

        return {
            ...formData,
            type: type[resourse],
            settings: {
                ...formData.settings,
                locales
            },
            slug: get(translatedSlug, "[0].en", "").replace(/[^a-zA-Z -\d]/g, "").replace(/[\s]/g, "-").toLowerCase()
        };
    }

    return (
        <CreateApi transform={transform} {...props}>
            <Tabs {...props} />
        </CreateApi>
    );
}