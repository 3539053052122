const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const REACT_APP_FACEBOOK_API_KEY = process.env.REACT_APP_FACEBOOK_API_KEY;

const REACT_APP_S3_API_URL = `${REACT_APP_BASE_API}/admin/s3/sign/`;
const REACT_APP_TRANSLATE_API_URL = `${REACT_APP_BASE_API}/admin/yc_service/translate`;

export {
    REACT_APP_S3_API_URL,
    REACT_APP_TRANSLATE_API_URL,
    REACT_APP_BASE_API,
    REACT_APP_FACEBOOK_API_KEY,
};
