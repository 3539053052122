import React from "react";
import {
    useTranslate,
    ArrayInput,
} from "react-admin";

import { Picture } from "../../Fields";
import { CustomTextInput, CustomRichText } from "../../Fields";
import SimpleFormIterator from "./SimpleFormIteratorDraggable";

export default function ImageGallery({ source }) {
    const translate = useTranslate();

    return (
        <ArrayInput source={`${source}.items`} label={translate("section.about-persons.add-person")}>
            <SimpleFormIterator>
                <CustomTextInput
                    label={translate("common-fields.heading")}
                    source={"title"}
                />
                <CustomTextInput
                    label={translate("common-fields.teaser")}
                    source={"subtitle"}
                />
                <CustomRichText
                    label={translate("common-fields.description")}
                    source={"description"}
                />
                <Picture
                    source="picture"
                    style={{ width: "100%" }}
                    label={translate("picture.name")}
                />
            </SimpleFormIterator>
        </ArrayInput>
    );
}