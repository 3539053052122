import React from "react";
import { useTranslate, useDataProvider, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import moment from "moment";

import { ListElements } from "../../Components/Containers";

export const ListView = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            source: "filename",
        },
        {
            type: "TextField",
            source: "description",
        },
        {
            type: "TextField",
            source: "url",
        },
    ];

    const bulkActionButtons = (props) => {
        const onClick = () => {
            dataProvider
                .updateMany(props.resource, {
                    ids: props.selectedIds,
                    data: { deleted_at: moment() }
                })
                .then(refresh)
                .catch(error => console.error(error));
        };

        return (
            <Button
                size={"small"}
                onClick={onClick}
                style={{
                    color: "#f44336"
                }}
                startIcon={<DeleteIcon />}
            >
                {translate("delete")}
            </Button>
        )
    }

    return <ListElements {...props} sort={{field: "created_at", order: "DESC"}} fields={fields} BulkActionButtons={bulkActionButtons} />;
};
