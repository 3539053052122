import { useTranslate } from "react-admin";

import { FaceBookInput } from "../../Fields"

export default function FacebookWidget({ type, source }) {
    const translate = useTranslate();

    return (
        <FaceBookInput
            label={translate("section.content.btn-label")}
            source={`${source}.faceBook.url`}
            style={{
                width: "48%",
                display: "inline-block",
            }}
        />
    )
}