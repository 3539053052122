import React from "react";
import { BulkDeleteButton } from "react-admin";

import { ListElements } from "../../Components/Containers";
import { ActionButtons } from "../../Components/Actions/ContentRequestActions"

export const ListView = (props) => {
    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            source: "source.name",
        },
        {
            type: "TextField",
            source: "source.url",
        },
        {
            type: "TextField",
            source: "cron",
        },
        {
            type: "FunctionField",
            render: record => record.state === "active" ? <span style={{color: "green"}}>{record.state}</span> : <span style={{color: "red"}}>{record.state}</span>,
            source: "state",
        },
        {
            type: "TextField",
            source: "lastRequest",
        },
    ];

    const BulkActionButtons = ({ basePath }) => <BulkDeleteButton basePath={basePath} />;

    return (
        <ListElements {...props} sort={{field: "created_at", order: "DESC"}} fields={fields} BulkActionButtons={BulkActionButtons} >
            <ActionButtons />
        </ListElements>
    );
};
