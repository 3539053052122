/* eslint-disable  */
import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    useTranslate,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export default function Tabs(props) {
    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <TextInput
                                source={"name"}
                                label={"Название настройки"}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"scope"}
                                label={"Scope"}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.metaTitle"}
                                label={"meta title"}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.metaDescription"}
                                label={"meta description"}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.twitterCardType"}
                                label={"twitter card type"}
                                style={{ width: "100%" }}
                            />
                            <TextInput
                                source={"data.twitterUsername"}
                                label={"twitter username"}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <FormTab label={translate("tabs.header")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <ArrayInput
                                source="data.header.navigation"
                                label="Главное меню"
                            >
                                <SimpleFormIterator>
                                    <TextInput
                                        source={"title"}
                                        label={translate("common-fields.title")}
                                    />
                                    <TextInput
                                        source={"link"}
                                        label={translate("common-fields.link")}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <FormTab label={translate("tabs.footer")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <ArrayInput
                                source="data.footer.navigation"
                                label="Нижнее меню"
                            >
                                <SimpleFormIterator>
                                    <TextInput
                                        source={"title"}
                                        label={translate("common-fields.title")}
                                    />
                                    <TextInput
                                        source={"link"}
                                        label={translate("common-fields.link")}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <FormTab label={translate("tabs.map")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{}}
                        >
                            <ArrayInput
                                label={translate("section.map.api-key")}
                                source={"data.map"}
                                style={{
                                    paddingLeft: 8,
                                }}
                            >
                                <SimpleFormIterator>
                                    <TextInput
                                        source="api-key"
                                        label={translate("section.map.api-key")}
                                        style={{ width: "50%" }}
                                    />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}