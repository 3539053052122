/* eslint-disable  */
import React, { useState, useMemo, useEffect, createElement } from "react";
import PropTypes from "prop-types";
import { SelectInput, SimpleFormIterator, useTranslate } from "react-admin";
import { get } from "lodash";

import formsHashMap from "../Forms"

import "./Common.scss";

function Wrapper(props) {
    const source = get(props, "source", "");
    const defaultType = get(props, "record.component", "Default");

	const [type, setType] = useState(defaultType);
    const translate = useTranslate();

    useEffect(() => {
        setType(defaultType);
    }, [defaultType]);

    return useMemo(() => {
        return (
            <div>
                <SelectInput
                    {...props}
                    onChange={(event) => setType(event.target.value)}
                    label={translate("section.type")}
                    source={`${source}.component`}
                    choices={[
                        {
                            id: "map",
                            name: translate("section.map.name"),
                        },
                        {
                            id: "about",
                            name: "О нас",
                        },
                        {
                            id: "common-news",
                            name: "Новости",
                        },
                        {
                            id: "projects",
                            name: "Проекты",
                        },
                        {
                            id: "content-flow",
                            name: "Контент",
                        },
                        {
                            id: "accordion",
                            name: "Аккордион",
                        },
                        {
                            id: "image-gallery",
                            name: "Галерея изображений",
                        },
                        {
                            id: "twitter-post",
                            name: "Вставка поста из Твиттер",
                        },
                        {
                            id: "facebook-post",
                            name: "Вставка поста из Фейсбука",
                        },
                        {
                            id: "about-persons",
                            name: "Список официальных лиц для стр. \"О фонде\"",
                        },
                    ]}
                />
                {
                    createElement(
                        formsHashMap.get(type),
                        {
                            type,
                            props,
                            source
                        },
                    )
                }
            </div>
        );
    }, [type]);
}

Wrapper.propTypes = {
    source: PropTypes.string,
};

Wrapper.defaultProps = {
    source: "",
};

export default function Common(props) {
    return (
        <SimpleFormIterator {...props}>
            <Wrapper />
        </SimpleFormIterator>
    );
}
