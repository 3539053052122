import React from "react";
import { FormTab, ArrayInput, useTranslate } from "react-admin";

import Common from "../../Common";

export function Content(props) {
    const translate = useTranslate();
    
    return (
        <FormTab label={translate("tabs.blocks")} {...props}>
            <ArrayInput source={"sections"} {...props}>
                <Common />
            </ArrayInput>
        </FormTab>
    );
}
