import React from "react";
import {
    TabbedForm,
    FormTab,
    TextInput,
    SelectInput,
    ReferenceInput,
    required,
    DateInput,
    useTranslate,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import last from "lodash/last";

import { Heading } from "../../Components/Blocks";
import { Content, SEO } from "../../Components/Tabs";
import { Picture } from "../../Components/Fields";

export default function Tabs(props) {
    const translate = useTranslate();

    /** If view is in creation mode */
    const isCreateMode = last(props.location.pathname.split("/")) === "create";

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <Heading
                                description={props.resource !== "v_news_admin"}
                                defaultTypeValue={"h1"}
                            />
                            {/v_news_admin/.test(props.resource) && (
                                <ReferenceInput
                                    source={"country_id"}
                                    reference={"v_news_countries"}
                                    label={translate("common-fields.country")}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        emptyValue={null}
                                        optionText="name.ru.auto"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            )}
                            {/v_projects_admin/.test(props.resource) && (
                                <ReferenceInput
                                    source={"country_id"}
                                    reference={"v_project_countries"}
                                    label={translate("common-fields.country")}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        emptyValue={null}
                                        optionText="name.ru.auto"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            )}
                            <ReferenceInput
                                source={"original_lang_id"}
                                reference={"countries"}
                                label={translate("common-fields.original-lang")}
                                style={{
                                    width: "100%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText="name.ru.auto"
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            {props.resource === "v_news_admin" && (
                                <ReferenceInput
                                    source={"category_id"}
                                    reference={"categories"}
                                    label={translate("common-fields.category")}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <SelectInput
                                        emptyValue={null}
                                        optionText="heading.title.ru.auto"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                            )}
                            {props.resource === "v_news_admin" && [
                                <SelectInput
                                    source={"settings.type"}
                                    label={translate("common-fields.news-type")}
                                    style={{
                                        width: "100%",
                                    }}
                                    defaultValue={"base"}
                                    choices={[
                                        {
                                            id: "base",
                                            name: translate("common-fields.simple-news"),
                                        },
                                        {
                                            id: "main",
                                            name: translate("common-fields.base-news"),
                                        },
                                        {
                                            id: "fixed",
                                            name: translate("common-fields.fixed-news"),
                                        },
                                    ]}
                                />,
                                <TextInput
                                    source={"settings.sortOrder"}
                                    label={translate("common-fields.position")}
                                />,
                            ]}
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            style={{
                                paddingLeft: 24,
                                marginBottom: 24,
                            }}
                        >
                            <TextInput
                                source={"slug"}
                                label={translate("common-fields.slug")}
                                style={{ width: "100%" }}
                                disabled={isCreateMode}
                            />
                            <TextInput
                                source={"sort_order"}
                                label={translate("common-fields.sort")}
                                style={{ width: "100%" }}
                                resettable
                            />
                            <DateInput
                                source={"published_at"}
                                label={translate("common-fields.published_at")}
                                style={{ width: "100%" }}
                                validate={[required()]}
                                resettable
                            />
                            <Picture
                                label={translate("common-fields.picture")}
                                source={"picture"}
                                mainImage
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            style={{
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            {props.children}
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
            <Content />
            <SEO />
        </TabbedForm>
    );
}
