import React from "react";
import {
    TabbedForm,
    FormTab,
    required,
    useTranslate,
    ReferenceInput,
    SelectInput,
    useLocale
} from "react-admin";
import Grid from "@material-ui/core/Grid";

export default function Tabs(props) {
    const locale = useLocale();

    const translate = useTranslate();

    return (
        <TabbedForm {...props}>
            <FormTab label={translate("tabs.base")} {...props}>
                <div style={{ width: "100%" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={8}
                            style={{
                                paddingRight: 24,
                                borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                            }}
                        >
                            <ReferenceInput
                                source={"country_id"}
                                reference={"countries"}
                                label={translate("common-fields.country")}
                                style={{
                                    width: "60%",
                                }}
                            >
                                <SelectInput
                                    emptyValue={null}
                                    optionText={`name.${locale}.auto`}
                                    validate={[required()]}
                                />
                            </ReferenceInput>
                            <SelectInput
                                source={"content_type"}
                                label={translate("common-fields.news-type")}
                                style={{
                                    width: "60%",
                                }}
                                defaultValue={"base"}
                                choices={[
                                    {
                                        id: "Content::News",
                                        name: translate("common-fields.news")
                                    },
                                    {
                                        id: "Content::Project",
                                        name: translate("common-fields.project"),
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                </div>
            </FormTab>
        </TabbedForm>
    );
}