import React from "react";

import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    ImageField,
    DateField,
    ReferenceManyField,
    SingleFieldList,
    ReferenceField,
    ChipField,
    Button,
    useTranslate,
    useLocale
} from "react-admin";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

export function ShowView(props) {
    const locale = useLocale();
    const translate = useTranslate();

    const AddButton = ({ redirect_to, record, table, label }) => (
        <Button
            variant="raised"
            component={Link}
            to={{
                pathname: `/${table}/create`,
                state: { record: { content_id: record.id }, redirect_to },
            }}
            label={label}
            title={label}
        >
            <AddIcon />
        </Button>
    );
    const AddReferenceButton = AddButton;

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={"slug"}
                        label={translate("common-fields.slug")}
                        style={{ width: "100%" }}
                    />
                    <ImageField source="picture[0].src" label={translate("picture.name")} />
                    <TextField source={`heading.title[${locale}].auto` || "heading.title.ru.auto"} label={translate("common-fields.title")} />
                    <DateField source="published_at" label={translate("common-fields.published_at")} />
                    {props.children}
                </Tab>
                {
                    props.resource === "v_news_admin" && (
                        <Tab label={translate("tabs.connects")} {...props}>
                            <ReferenceManyField
                                label={translate("resources.content_connects.forward-news")}
                                reference="linked_contents"
                                target="content_id"
                                sort={{ field: "content_id", order: "ASC" }}
                            >
                                <SingleFieldList>
                                    <ReferenceField
                                        source="linked_content_id"
                                        reference="v_news_admin"
                                        link={false}
                                    >
                                        <ChipField source={`heading.title[${locale}.auto]`} />
                                    </ReferenceField>
                                </SingleFieldList>
                            </ReferenceManyField>
                            <AddReferenceButton
                                redirect_to={props.location.pathname}
                                table={"linked_contents"}
                                label={translate("resources.content_connects.attach-news")}
                            />

                            <ReferenceManyField
                                label={translate("resources.content_connects.backward-news")}
                                reference="linked_contents"
                                target="linked_content_id"
                                sort={{ field: "content_id", order: "ASC" }}
                            >
                                <SingleFieldList>
                                    <ReferenceField
                                        source="content_id"
                                        reference="v_news_admin"
                                        link={false}
                                    >
                                        <ChipField source={`heading.title[${locale}.auto]`} />
                                    </ReferenceField>
                                </SingleFieldList>
                            </ReferenceManyField>
                            <AddReferenceButton
                                redirect_to={props.location.pathname}
                                table={"linked_contents"}
                                label={translate("resources.content_connects.attach-news")}
                            />
                        </Tab>
                    )
                }
            </TabbedShowLayout>
        </Show>
    );
}
