/* eslint-disable */

import React, { useEffect, useReducer } from "react";
import { addField } from "ra-core";
import { showNotification, useTranslate } from "react-admin";
import { useDispatch } from "react-redux";

import { Button } from "@material-ui/core";
import { DeleteForever, Image } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dropzone from "react-dropzone-uploader";

import { get } from "lodash";

import { REACT_APP_S3_API_URL } from "../../../configs";
import { loadData } from "../../../utils/index";

import "react-dropzone-uploader/dist/styles.css";
import "cropperjs/dist/cropper.css";

// import "./uploaderStyles.scss";

function InputS3Uploader({
        input,
        label = "",
        style = {},
    }) {
    const reduxDispatch = useDispatch();
    const translate = useTranslate();

    let initialFile = { src: get(input, "value") };

    const STATE = {
        init: {
            showDownload: true,
            showUploadedFile: false,
        },
        existImg: {
            showDownload: false,
            showUploadedFile: true,
        },
        onUploadImg: {
            showDownload: false,
            showUploadedFile: false,
        },
    };

    const reducer = (state, action) => {
        const { type, payload } = action;

        switch (type) {
            case "INIT":
                return {
                    ...state,
                    file: payload,
                    appState: STATE.existImg,
                };
            case "ON_UPLOLOAD_IMG":
                return {
                    ...state,
                    file: payload,
                    appState: STATE.onUploadImg,
                };
            case "SAVE":
                return {
                    ...state,
                    file: payload,
                    saveToS3: true,
                    loading: true,
                };
            case "UPLOADING":
                return {
                    ...state,
                    saveToS3: true,
                    loading: true,
                };
            case "S3":
                return {
                    ...state,
                    s3Path: payload,
                };
            case "DELETE":
                return {
                    ...state,
                    file: {},
                    appState: STATE.init,
                    compressed: false,
                };
            case "FINISHED":
                return {
                    ...state,
                    file: {
                        ...state.file,
                        ...payload
                    },
                    appState: {
                        ...STATE.existImg,
                        finished: true,
                    },
                    saveToS3: false,
                    loading: false,
                };
            default:
                return state;
        }
    };

    const initialState = () => ({
        file: {},
        appState: STATE.init,
        s3Path: {},
        saveToS3: false,
        loading: false,
    });

    const [state, dispatch] = useReducer(reducer, {}, initialState);

    const {
        file,
        s3Path,
        saveToS3,
        loading,
        appState: {
            showDownload,
            showUploadedFile,
        } = STATE.init,
    } = state;

    useEffect(() => {
        if (initialFile.src) {
            dispatch({
                type: "INIT",
                payload: initialFile,
            });
            initialFile = null;
        }
    }, []);

    useEffect(() => {
        if (saveToS3) {
            getS3Path();
        }
    }, [saveToS3]);

    useEffect(() => {
        putToS3();
    }, [s3Path]);


    function handleDelete() {
        const onChange = get(input, "onChange", () => Function);
        onChange("");
        dispatch({ type: "DELETE" });
    }

    function onChangeStatus(data) {
        const { meta } = data;

        if (!get(file, "src", "")) {
            const payload = {
                file: get(data, "file", ""),
                objectName: encodeURIComponent(
                    get(meta, "name", "")
                        .replace(/[\s]+/gi, "_")
                        .replace(/[^\wа-яА-ЯёЁ\d_\-\.]+/gi, "")
                ),
                name: get(meta, "name", ""),
                contentType: get(meta, "type", ""),
                size: get(meta, "size", 0),
                loaded: true,
            };

            dispatch({
                type: "SAVE",
                payload,
            });
        }
    }

    function getS3Path() {
        reduxDispatch(showNotification(translate("picture.loading")));

        if (s3Path.path) {
            putToS3();

            return;
        }

        const options = {
            headers: {
                "x-amz-acl": "public-read",
                "Cache-Control": "max-age=31536000"
            }
        }

        const url = `${REACT_APP_S3_API_URL}?objectName=${get(file, "objectName", get(file, "name", ""))}&contentType=${get(file, "contentType", "image/jpeg")}&kind=serafim-tours-uploads&path=content`;

        loadData(url, options)
            .then((data) =>
                dispatch({
                    type: "S3",
                    payload: {
                        path: get(data, "signedUrl", ""),
                    },
                })
            )
            .catch((error) => {
                console.error("Error:" + error);
            });
    }

    function putToS3() {
        const data = get(file, "file", "");

        const type = get(file, "contentType", "image/jpeg");

        const body = new File([data], "image_data_url.jpg", {
            type,
        });

        const headers = new Headers();

        headers.append("x-amz-acl", "public-read");
        headers.append("Cache-Control", "no-cache");
        headers.append("Content-Type", type);
        headers.append(
            "Content-Disposition",
            `inline; filename="${get(
                file,
                "objectName",
                get(file, "name", "")
            )}"`
        );

        const request = new Request(s3Path.path, {
            headers,
            method: "PUT",
            body,
        });

        fetch(request)
            .then(
                (response) =>
                    !!get(response, "ok", false) && response
            )
            .then(() => {
                const { onChange = () => {} } = input;

                const src = get(s3Path.path.split("?"), "[0]", "");

                onChange(src);

                console.log(src)

                dispatch({
                    type: "FINISHED",
                    payload: {
                        src
                    },
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div
            className={"drug-crop-uploader"}
            style={Object.assign(
                {},
                {
                    width: "100%",
                    textAlign: "center",
                    display: "block",
                },
                style
            )}
        >
            <h3 style={{ textAlign: "left", marginRight: 8 }}>{label}</h3>
            {showDownload && (
                <Dropzone
                    onChangeStatus={onChangeStatus}
                    multiple={false}
                    inputContent={
                        <div className={"dropzone"}>
                            <Image style={{ marginRight: ".5rem" }} />
                            {translate("picture.download")}
                        </div>
                    }
                />
            )}
            {showUploadedFile && (
                <>
                    <Button
                        variant={"contained"}
                        color={"default"}
                        style={{
                            width: "100%",
                            marginBottom: 16,
                        }}
                        onClick={handleDelete}
                    >
                        <DeleteForever style={{ marginRight: ".5rem" }} />
                        {translate("ra.action.delete")}
                    </Button>
                    <div className={"uploader__file"} style={{}}>
                        {file.src}
                    </div>
                </>
            )}
            {loading && <CircularProgress />}
        </div>
    );
}

export const FileUploader = addField(InputS3Uploader);
