import React from "react";
import { SelectInput, useTranslate } from "react-admin";
import PropTypes from "prop-types";

import { CustomTextInput, CustomRichText } from "../../Fields";

export function Heading({ source, description, defaultTypeValue }) {
    const translate = useTranslate();

    return (
        <>
            <SelectInput
                key={"type"}
                source={source ? `${source}.type` : "heading.type"}
                label={translate("common-fields.heading-type")}
                style={{ width: "100%" }}
                defaultValue={defaultTypeValue}
                choices={[
                    {
                        id: "h1",
                        name: "H1",
                    },
                    {
                        id: "h2",
                        name: "H2",
                    },
                    {
                        id: "h3",
                        name: "H3",
                    },
                    {
                        id: "h4",
                        name: "H4",
                    },
                    {
                        id: "h5",
                        name: "H5",
                    },
                    {
                        id: "h6",
                        name: "H6",
                    },
                ]}
            />
            <CustomTextInput
                label={translate("common-fields.heading")}
                source={source ? `${source}.title` : "heading.title"}
            />
            <CustomTextInput
                label={translate("common-fields.teaser")}
                source={
                    source ? `${source}.subtitle` : "heading.subtitle"
                }
            />
            {description && (
                <CustomRichText
                    label={translate("common-fields.description")}
                    source={
                        source
                            ? `${source}.description`
                            : "heading.description"
                    }
                />
            )}
        </>
    );
}

Heading.propTypes = {
    source: PropTypes.string,
    description: PropTypes.bool,
};

Heading.defaultProps = {
    source: "",
    description: true,
};
