import React from "react";
import {
    ArrayInput,
    SimpleFormIterator,
    SelectInput, 
    useTranslate,
    TextInput
} from "react-admin";
import { addField } from "ra-core";

import { InputS3UploaderField } from "../InputS3DragCropUploader";
import { CustomTextInput } from "../CustomInput";

function Pictures({ source, label, mainImage = false }) {
    const translate = useTranslate();

    return (
        <div>
            <h3 style={{ textAlign: "left", marginRight: 8 }}>{label}</h3>

            <ArrayInput source={source} label="">
                <SimpleFormIterator>
                    {!mainImage && (
                        <SelectInput
                            source={"breakpoint"}
                            label={translate("picture.device")}
                            defaultValue={"main"}
                            style={{
                                width: "100%",
                            }}
                            choices={[
                                {
                                    id: "main",
                                    name: "main",
                                },
                                {
                                    id: "md",
                                    name: "md",
                                },
                                {
                                    id: "lg",
                                    name: "lg",
                                },
                            ]}
                        />
                    )}
                    {mainImage && <TextInput source={"breakpoint"} style={{ display: "none"}} initialValue={"main"} /> }
                    <CustomTextInput
                        source={"title"}
                        label={translate("picture.title")}
                    />

                    <CustomTextInput
                        source={"alt"}
                        label={translate("picture.alt")}
                    />
                    <InputS3UploaderField
                        source={"src"}
                        label={translate("common-fields.picture")}
                        needCrop
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </div>
    );
}

export const Picture = addField(Pictures);