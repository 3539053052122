import React from "react";

import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField source={"source.name"} label={translate("common-fields.source. name")} />
                    <TextField source={"source.fullName"} label={translate("common-fields.source.fullName")} />
                    <TextField source={"source.url"} label={translate("common-fields.sourceUrl")} />
                    <TextField source={"source.rssUrl"} label={translate("common-fields.sourceRss")} />
                    <TextField source={"data.title"} label={translate("common-fields.title")} />
                    <TextField source={"data.description"} label={translate("common-fields.teaser")} />
                    <TextField source={"data.fullText"} label={translate("common-fields.fullText")} />
                    <TextField source="data.enclosure" label={translate("picture.name")} />
                    <TextField source="data.published_at" label={translate("common-fields.published_at")} />
                    <TextField source="data.link" label={translate("common-fields.link")} />
                    <TextField source="type" label={translate("common-fields.type")} />
                    <TextField source="state" label={translate("common-fields.state")} />
                    <TextField source="cron" label={translate("common-fields.cron")} />
                    {props.children}
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}
