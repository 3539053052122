import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    useTranslate,
} from "react-admin";

export function ShowView(props) {
    const translate = useTranslate();

    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label={translate("tabs.base")} {...props}>
                    <TextField
                        source={"country_id"}
                        label={translate("common-fields.country")}
                        style={{ width: "100%" }}
                    />
                    <TextField
                        source={"content_type"}
                        label={translate("common-fields.content-type")}
                        style={{ width: "100%" }}
                    />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}