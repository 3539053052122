import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

const DragHandle = sortableHandle(() => <div className={"draggable__handle"} />);
const SortableItem = sortableElement(({ children }) => <li className={"draggable__item"}><DragHandle />{children}</li>);
const SortableContainer = sortableContainer(({ children }) => <ul className={"draggable"}>{children}</ul>);

function AccordionWrapper(props) {
	const { children, title, index, isOpened, handleChange } = props;

	return (
		<SortableItem index={index} disabled={isOpened}>
			<Accordion
				TransitionProps={{ unmountOnExit: true }}
				onChange={handleChange(true)}
			>
				<AccordionSummary
					id={"panel1a-header"}
					aria-controls={"panel1a-content"}
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={"typography"}>
						{title}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div style={{ width: "100%" }}>
						<Grid container>
							<Grid
								item
								xs={12}
								style={{
									paddingRight: 24,
								}}
							>
								{children}
							</Grid>
						</Grid>
					</div>
				</AccordionDetails>
			</Accordion>
		</SortableItem>
	);
}

AccordionWrapper.propTypes = {
	children: PropTypes.arrayOf(PropTypes.node),
	title: PropTypes.string,
	index: PropTypes.number,
	isOpened: PropTypes.bool,
	handleChange: PropTypes.func,
};

AccordionWrapper.defaultProps = {
	children: <></>,
	title: "",
};

export { SortableItem, SortableContainer, AccordionWrapper };
