/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextInput, useTranslate } from "react-admin";
import { addField } from "ra-core";
import get from "lodash/get";

import Button from "@material-ui/core/Button";
import TranslateIcon from "@material-ui/icons/Translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useTranslate as useTranslateWidget } from "../../../widgets";
import { translate as translateApi } from "./helpers";

function Input(props) {
    const { input, source, label, style = {} } = props;
    const { onChange } = input;

    const translate = useTranslate();
    const { locale, translateState } = useTranslateWidget();

    const [value, setValue] = useState(get(input, `value[${locale}].auto`, ""));
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    function setInputValue(val) {
        const data = {
            ...input.value,
            [locale]: {
                ...input.value[locale],
                manual: val,
            },
        };

        onChange(data);
    }

    const localesToTranslate = Object.entries(translateState).reduce(
        (locales, [key, val]) => {
            val && locales.push(key);
            return locales;
        },
        []
    );

    function translateText() {
        setLoading(true);
        translateApi(value, localesToTranslate, locale, input)
            .then((data) => {
                setLoading(false);
                onChange(data);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
                setOpen(true);
            });
    }

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    function clear() {
        setValue("");
        onChange({});
    }

    return (
        <div
            style={Object.assign(
                {},
                {
                    border: "1px solid #ccc",
                    padding: "10px",
                    margin: "10px 0",
                    borderRadius: "5px",
                },
                style
            )}
        >
            <h4 style={{
                    margin: "10px 0"
                }}
            >
                {label}
            </h4>
            <TextInput
                key="manual"
                source={`${source}.${locale}.manual`}
                label={`${label}`}
                style={{ width: "100%" }}
                onChange={(event) => {
                    let val = get(event, "target.value");
                    setValue(val);
                    setInputValue(val);
                }}
                resettable
            />
            <TextInput
                key="auto"
                source={`${source}.${locale}.auto`}
                label={`${label}: ${translate("auto-translate-label")}`}
                style={{ width: "100%" }}
                disabled
            />
            <Button 
                variant="outlined" 
                onClick={translateText}
            >
                <span style={{ marginRight: 8 }}>{translate("translate")}</span>{" "}
                <TranslateIcon size={12} />
                {loading && (
                    <CircularProgress style={{ marginLeft: 10 }} size={12} />
                )}
            </Button>
            <Button
                variant="outlined"
                onClick={clear}
                style={{marginLeft: 10}}
            >
                <span>{translate("clear")}</span>
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={open}
                autoHideDuration={4000}
                onClose={handleClose}
                message={translate("translating-error")}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            />
        </div>
    );
}

export const CustomTextInput = addField(Input);


Input.propTypes = {
    input: PropTypes.object,
};
Input.defaultProps = {
    input: {},
};