import get from "lodash/get";

import { translater } from "../../../utils";

export async function translate(
    text,
    localesToTranslate,
    locale,
    input
) {
    if (text) {
        try {
            const translatedArrayFromApi = await translater(
                text,
                localesToTranslate
            );

            const translatedlocalesObject = translatedArrayFromApi.reduce(
                (result, item) => {
                    for (const [key, value] of Object.entries(item)) {
                        result[key] = {
                            auto: value,
                            manual: get(input, `value[${key}].manual`, ""),
                        };
                    }
                    return result;
                },
                {}
            );

            return Promise.resolve(
                Object.assign({}, translatedlocalesObject, {
                    [locale]: {
                        auto: text,
                        manual: text,
                    },
                })
            );
        } catch (error) {
            return Promise.reject(error);
        }
    }
}
