import React from "react";

import { CreateApi } from "../../Components/FormApi";
import Tabs from "./Tabs";

export function CreateView(props) {
    async function transform(formData) {
        return {
            ...formData,
            type: "ContentSource::Xpath",
        };
    }

    return (
        <CreateApi transform={transform} {...props} needTranslate={false}>
            <Tabs {...props} />
        </CreateApi>
    );
}