/* eslint-disable  */
import React from "react";
import {
    useCreateController,
    CreateContextProvider,
    FormTab,
    TabbedForm,
    TextInput,
    PasswordInput,
    required,
    useTranslate
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";

import PropTypes from "prop-types";

function CreateApi(props) {
    const createControllerProps = useCreateController(props);
    const history = useHistory();

    const {
        basePath,
        record,
        redirect,
        resource,
        saving,
        version,
    } = createControllerProps;

    async function save(formData) {
        const variables = Object.assign({}, formData);

        delete variables.confirmPassword;

        fetch(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}/v1/graphql`, {
            method: "POST",
            credentials: "include",
            headers: {
                authorization: `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify({
                query: "mutation MyMutation($email: String!, $name: String!, $password: String!) { createModerator(email: $email, name: $name, password: $password) }",
                variables
            })
        })
        .then(response => response.json())
        .then(data => {
            if(data.errors) {
                console.error(data.errors);
            } else {
                history.push("/v_moderators_admin");
                
            }
        })
        .catch(error => {
            console.error(error);
        });
    }

    return (
            <CreateContextProvider value={createControllerProps}>
                <div>
                    {React.cloneElement(props.children, {
                        basePath,
                        record,
                        redirect,
                        resource,
                        save,
                        saving,
                        version,
                    })}
                </div>
            </CreateContextProvider>
    );
};

CreateApi.propTypes = {
    transform: PropTypes.func,
    children: PropTypes.node,
};

CreateApi.defaultProps = {
    transform: undefined,
};

export function CreateView(props) {
    const translate = useTranslate();
    
    function passwordValidation(_, allValues) {
        if(allValues.password !== allValues.confirmPassword) {
            return translate("resources.v_moderators_admin.fields.confirmPasswordError")
        }

        return undefined;
    }

    return (
        <CreateApi {...props}>
            <TabbedForm {...props}>
                <FormTab label={translate("tabs.base")} {...props}>
                    <div style={{ width: "100%" }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    paddingLeft: 24,
                                    marginBottom: 24,
                                }}
                            >
                                <TextInput
                                    source={"name"}
                                    label={translate("resources.v_moderators_admin.fields.name")}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                />
                                <TextInput
                                    source={"email"}
                                    label={translate("resources.v_moderators_admin.fields.email")}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                />
                                <PasswordInput 
                                    source={"password"}
                                    label={translate("resources.v_moderators_admin.fields.password")}
                                    style={{ width: "100%" }}
                                    validate={[required()]}
                                />
                                <PasswordInput 
                                    source={"confirmPassword"}
                                    label={translate("resources.v_moderators_admin.fields.confirmPassword")}
                                    style={{ width: "100%" }}
                                    validate={[required(), passwordValidation]}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </CreateApi>
    )
}