import React from "react";
import PropTypes from "prop-types";
import ReactMde from "react-mde";
import { addField } from "ra-core";
import * as Showdown from "showdown";
import { useToggle } from "@umijs/hooks";

import "react-mde/lib/styles/css/react-mde-all.css";

const converter = new Showdown.Converter({
	tables: true,
	simplifiedAutoLink: true,
	strikethrough: true,
	tasklists: true,
});

function Rich(props) {
    const { input, onChange, value } = props;

    const { state: tab, toggle: toggleTab } = useToggle("write", "preview");

    return (
        <ReactMde
            value={input.value || value}
            onChange={onChange}
            selectedTab={tab}
            onTabChange={toggleTab}
            generateMarkdownPreview={(markdown) =>
                Promise.resolve(converter.makeHtml(markdown))
            }
        />
    );
}

export const RichText = addField(Rich);

Rich.propTypes = {
	input: PropTypes.object,
};

Rich.defaultProps = {
	input: {},
};
