/* eslint-disable  */
import React from "react";
import {
    useEditController,
    EditContextProvider,
    useDataProvider,
    useTranslate,
    useLocale,
} from "react-admin";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import get from "lodash/get";
import {
    Grid,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import { useTranslate as useTranslateWidget } from "../../widgets";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
}));

export function EditApi(props) {
    const classes = useStyles();
    let history = useHistory();
    const controllerProps = useEditController(props);
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const locale = useLocale();

    const { handleChangeLocale, handleSetTranslateState, translateState } = useTranslateWidget();

    const {
        basePath,
        record,
        redirect,
        resource,
        saving,
        version,
    } = controllerProps;

    // Save edited data
    function save(formData) {
        const { transform, onSuccess } = props;
        let transformedData = {};

        if (transform) {
            transformedData = transform(Object.assign({}, formData));
        }

        const preparedData = Object.assign(
            {},
            transform ? transformedData : formData
        );

        delete preparedData.id;

        dataProvider
            .update(resource, {
                id: get(record, "id", ""),
                data: { ...preparedData },
            })
            .then(() => {
                if (onSuccess) {
                    onSuccess.call(this, formData);
                }

                history.push(`/${resource}`);
            })
            .catch((error) => {
                //TODO: logout user here if 401
                console.error("Ошибка сохранения видео: ", { error });
            });
    }

    function onLocalesChecked(event) {
        handleSetTranslateState({
            ...translateState,
            [event.target.name]: event.target.checked,
        });
    }

    function onLocaleChange(event, child) {
        handleChangeLocale(child.props.value);
    }

    const localesSelectMap = [
        { code: "ru", title: { ru: "Русский", en: "Russian" } },
        { code: "en", title: { ru: "Английский", en: "English" } },
        { code: "el", title: { ru: "Греческий", en: "Greece" } },
        { code: "sr", title: { ru: "Сербский", en: "Serbia" } },
        { code: "fr", title: { ru: "Французский", en: "France" } },
        { code: "ge", title: { ru: "Грузинский", en: "Georgia" } },
        { code: "al", title: { ru: "Албанский", en: "Albania" } },
        { code: "ae", title: { ru: "Арабский", en: "UAE" } },
        { code: "he", title: { ru: "Иврит", en: "Israel" } },
        { code: "tr", title: { ru: "Турецкий", en: "Turkey" } },
        { code: "cn", title: { ru: "Китайский", en: "China" } },
        { code: "ja", title: { ru: "Японский", en: "Japan" } },
    ];

    return (
            <EditContextProvider value={controllerProps}>
                <div>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item xs={6}>
                            <h1>
                                {translate("edit")}{" "}
                                {record
                                    ? `"${get(
                                          record,
                                          "heading.title.ru.manual",
                                          get(record, "name.ru.manual",
                                          get(record, "name", get(record, "source.name", get(record, "filename"))))
                                      )}"`
                                    : ""}
                            </h1>
                        </Grid>
                        {
                            props.needTranslate && (
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="demo-simple-select-label">
                                            {translate("current-lang")}
                                        </InputLabel>
                                        <Select
                                            label={translate("current-lang")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={"ru"}
                                            onChange={(e, v) => onLocaleChange(e, v)}
                                        >
                                            {localesSelectMap.map(({ code, title }) => (
                                                <MenuItem value={code}>
                                                    {title[locale]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }
                    </Grid>
                    {
                        props.needTranslate && (
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                            >
                                <Grid item xs={12}>
                                    <FormGroup row>
                                        {localesSelectMap.map(
                                            ({ code, title }, idx) => {
                                                return (
                                                    <FormControlLabel
                                                        key={idx}
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    translateState[code]
                                                                }
                                                                onChange={
                                                                    onLocalesChecked
                                                                }
                                                                name={code}
                                                            />
                                                        }
                                                        label={title[locale]}
                                                        labelPlacement="top"
                                                    />
                                                );
                                            }
                                        )}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        )
                    }

                    {React.cloneElement(props.children, {
                        basePath,
                        record,
                        redirect,
                        resource,
                        save,
                        saving,
                        version,
                    })}
                </div>
            </EditContextProvider>
    );
};

EditApi.propTypes = {
    children: PropTypes.node,
    needTranslate: PropTypes.bool,
};

EditApi.defaultProps = {
    needTranslate: true
};
