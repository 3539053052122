import React from "react";
import { BulkDeleteButton } from "react-admin";

import { ListElements } from "../../Components/Containers";

export const ListView = (props) => {
    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            source: "country_id",
        },
        {
            type: "TextField",
            source: "content_type",
        },
    ];

    const BulkActionButtons = ({ basePath }) => <BulkDeleteButton basePath={basePath} />;

    return <ListElements {...props} fields={fields} BulkActionButtons={BulkActionButtons} />;
};
