import raLocaleRu from "ra-language-russian";
import raLocaleEn from "ra-language-english";

const messages = {
    ru: {
        ...raLocaleRu,
        "resources": {
            "settings": {
                "name": "Настройки",
                "fields": {
                    "name": "Заголовок",
                    "id": "aidi",
                    "scope": "Окружение"
                }
            },
            "v_news_admin": {
                "name": "Новости",
                "fields": {
                    "heading.title.ru.auto": "Заголовок",
                    "slug": "Слаг",
                    "published_at": "Дата публикации",
                    "picture[0].src": "Изображение"
                }
            },
            "v_text_pages": {
                "name": "Страницы",
                "fields": {
                    "slug": "Слаг",
                    "published_at": "Дата публикации",
                    "picture[0].src": "Изображение"
                }
            },
            "v_projects_admin": {
                "name": "Проекты",
                "fields": {
                    "slug": "Слаг",
                    "published_at": "Дата публикации",
                    "picture[0].src": "Изображение"
                }
            },
            "v_moderators_admin": {
                "name": "Администраторы",
                "fields": {
                    "email": "Почта",
                    "name": "Имя",
                    "password": "Пароль",
                    "confirmPassword": "Повторите пароль",
                    "confirmPasswordError": "Пароли должны совпадать"
                },

            },
            "countries": {
                "name": "Страны",
                "fields": {
                    "name": "Название",
                    "slug": "Слаг",
                    "code": "Код страны",
                }
            },
            "categories": {
                "name": "Категории новостей",
                "fields": {
                    "slug": "Слаг",
                    "heading.title": "Заголовок",
                    "picture[0].src": "Изображение"
                }
            },
            "content_connects": {
                "name": "Новость",
                "forward-news": "Новости связанные вперед",
                "backward-news": "Новости связанные назад",
                "attach-news": "Прикрепить новости",
            },
            "author_requests": {
                "name": "Авторы",
                "reject": "Отклонить",
                "approve": "Принять",
                "approve-success": "Заявка на авторство принята",
                "reject-success": "Заявка на авторство отклонена",
                "request-errror": "Ошибка выполнения запроса",
                "fields": {
                    "name": "Имя",
                    "email": "Почта",
                    "state": "Статус",
                },
            },
            "content_countries": {
                "name": "Классификатор стран",
                fields: {
                    "country_id": "Страна",
                    "content_type": "Тип контента"
                }
            },
            "v_content_sources_rss": {
                "name": "Источники из RSS",
                fields: {
                    "source.name": "Название",
                    "source.url": "Источник",
                    "content_type": "Тип контента",
                    "cron": "Крон",
                    "state": "Статус",
                    "lastRequest": "Последний запрос",
                }
            },
            "v_content_sources_norss": {
                "name": "Источники xPath",
                fields: {
                    "source.name": "Название",
                    "source.url": "Источник",
                    "cron": "Крон",
                    "state": "Статус",
                    "lastRequest": "Последний запрос",
                }
            },
            "v_content_sources_facebook": {
                "name": "Источники из Facebook",
                fields: {
                    "source.name": "Название",
                    "source.url": "Источник",
                    "cron": "Крон",
                    "state": "Статус",
                    "lastRequest": "Последний запрос",
                }
            },
            "v_content_sources_youtube": {
                "name": "Источники из Youtube",
                fields: {
                    "source.name": "Название",
                    "source.url": "Источник",
                    "cron": "Крон",
                    "state": "Статус",
                    "lastRequest": "Последний запрос",
                }
            },
            "subscribers": {
                "name": "Подписчики",
                fields: {
                    "created_at": "Время подписки"
                }
            },
            "v_partners_admin": {
                "name": "Партнёры",
                "fields": {
                    "data.href": "Ссылка",
                    "main": "Является ключевой",
                    "picture[0].src": "Изображение"
                }
            },
            "files": {
                name: "Файлы",
                fields: {
                    "filename": "Название",
                    "description": "Описание",
                    "url": "Ссылка на ресурс"
                }
            }
        },

        "current-lang": "Язык контента",
        "create": "Создать",
        "delete": "Удалить",
        "edit": "Редактируется",
        "translate": "Перевести",
        "auto-translate-label": "автоперевод",
        "clear": "Очистить",
        "translating-error": "Ошибка перевода",
        "content-sources-active": "Источник новостей включен",
        "content-sources-inactive": "Источник новостей отключен",
        "request-errror": "Ошибка выполнения запроса",
        "enable": "Включить",
        "disable": "Выключить",

        "tabs": {
            "base": "Основное",
            "blocks": "Блоки на странице",
            "header": "Шапка",
            "footer": "Подвал",
            "connects": "Связи",
            "map": "Настройки карты",
            "rssList": "Настройки списка",
            "rssSettings": "Настройки rss",
            "parserSettings": "Настройки парсера",
        },

        "common-fields": {
            "name": "Название",
            "title": "Заголовок",
            "heading-type": "Тип заголовка",
            "heading": "Заголовок",
            "teaser": "Тизер",
            "description": "Описание",
            "original-lang": "Язык оригинала",
            "slug": "Слаг",
            "sort": "Сортировка",
            "link": "Ссылка на страницу",
            "picture": "Изображение",
            "published_at": "Дата публикации",
            "country": "Страна",
            "category": "Категория",
            "news-type": "Тип новости",
            "content-type": "Тип контента",
            "position": "Позиция",
            "simple-news": "Простая новость",
            "base-news": "Основная новость",
            "fixed-news": "Фиксированная новость",
            "email": "Почта",
            "news": "Новость",
            "project": "Проект",
            "cron": "Крон",
            "cron.hour": "Каждый час",
            "cron.day": "Каждый день",
            "cron.week": "Каждую неделю",
            "sourceUrl": "Ссылка на ресурс",
            "sourceRss": "Ссылка на rss",
            "source.name": "Название источника",
            "source.fullName": "Полное название источника",
            "data.listSource": "Урл страницы новостей",
            "data.publichedxpath": "Xpath даты публикации",
            "data.newsxpath": "Xpath урла новости",
            "fullText": "Основной текст",
            "originalLink": "Ссылка на оригинал",
            "item": "Блок одной статьи",
            "contentTitle": "Заголовок контента",
            "contentDescription": "Описание контента",
            "contentPicture": "Изображение контента",
            "contentPictureGallery": "Галерея изображений",
            "url": "Ссылка на ресурс"
        },

        "picture": {
            "name": "Изображение",
            "device": "Устройство",
            "title": "Заголовок",
            "alt": "Альтернатиный текст",
            "crop": "Обрезать",
            "set-original": "Оставить оригинал",
            "download": "Загрузить",
            "loading": "Изображение сохраняется"
        },

        "section": {
            "type": "Компонент секции",
            "map": {
                "name": "Карта",
                "api-key": "api-key карты"
            },
            "about": {
                "body": "Содержимое контента"
            },
            "content": {
                "name": "Контент",
                "type": "Тип контента",
                "heading-type": "Тип заголовка",
                "title": "Заголовок контента",
                "subtitle": "Подаголовок контента",
                "body": "Содержимое контента",
                "link-to-video": "Cсылка на видео",
                "link-to-vk-post": "Cсылка на пост vk",
                "img": "Прикрепить изображение",
                "accordion": "Добавить элемент аккордиона",
                "file": "Прикрепить файл",
                "file-desc": "Подпись к файлу",
                "btns": "Кнопки",
                "btn-label": "Надпись на кнопке"
            },
            "image-gallery": {
                "name": "Галерея",
                "title": "Заголовок",
                "thumbnail": "Миниатюра"
            },
            "about-persons": {
                "add-person": "Добавить персону",
            }
        }
    },
    en: {
        ...raLocaleEn,
        "resources": {
            "settings": {
                "name": "Settings",
                "fields": {
                    "name": "Title",
                    "id": "id",
                    "scope": "Scope"
                }
            },
            "v_news_admin": {
                "name": "News",
                "fields": {
                    "heading.title.en.auto": "Title",
                    "slug": "Slug",
                    "published_at": "Published date",
                    "picture[0].src": "Picture"
                }
            },
            "v_text_pages": {
                "name": "Text pages",
                "fields": {
                    "slug": "Slug",
                    "published_at": "Published date",
                    "picture[0].src": "Picture"
                }
            },
            "v_projects_admin": {
                "name": "Projects",
                "fields": {
                    "slug": "Slug",
                    "published_at": "Published date",
                    "picture[0].src": "Picture"
                }
            },
            "v_moderators_admin": {
                "name": "Moderators",
                "fields": {
                    "email": "Email",
                    "name": "Name",
                    "password": "Passwords",
                    "confirmPassword": "Repeat password",
                    "confirmPasswordError": "Passwords must match"
                }
            },
            "countries": {
                "name": "Countries",
                "fields": {
                    "name": "Name",
                    "slug": "Slug",
                    "code": "Country code"
                }
            },
            "categories": {
                "name": "News categories",
                "fields": {
                    "heading.title": "Title",
                    "slug": "Slug",
                    "picture[0].src": "Picture"
                }
            },
            "content_connects": {
                "name": "News",
                "forward-news": "News related forward",
                "backward-news": "News related back",
                "attach-news": "Attach news",
            },
            "author_requests": {
                "name": "Authors",
                "reject": "Reject",
                "approve": "Approve",
                "approve-success": "Attribution accepted",
                "reject-success": "Attribution rejected",
                "request-errror": "Request execution error",
                "fields": {
                    "name": "Name",
                    "email": "Email",
                    "state": "State",
                },
            },
            "content_countries": {
                "name": "Country classifier",
                fields: {
                    "country_id": "Country",
                    "content_type": "Content type"
                }
            },
            "v_content_sources_rss": {
                "name": "Sources from RSS",
                fields: {
                    "source.name": "Title",
                    "source.url": "Source",
                    "cron": "Cron line",
                    "state": "State",
                    "lastRequest": "Last request",
                }
            },
            "v_content_sources_norss": {
                "name": "Sources xPath",
                fields: {
                    "source.name": "Title",
                    "source.url": "Source",
                    "cron": "Cron line",
                    "state": "State",
                    "lastRequest": "Last request",
                }
            },
            "v_content_sources_facebook": {
                "name": "Sources from Facebook",
                fields: {
                    "source.name": "Title",
                    "source.url": "Source",
                    "cron": "Cron line",
                    "state": "State",
                    "lastRequest": "Last request",
                }
            },
            "v_content_sources_youtube": {
                "name": "Sources from Youtube",
                fields: {
                    "source.name": "Title",
                    "source.url": "Source",
                    "cron": "Cron line",
                    "state": "State",
                    "lastRequest": "Last request",
                }
            },
            "subscribers": {
                "name": "Subscribers",
                fields: {
                    "created_at": "Subscription time"
                }
            },
            "v_partners_admin": {
                name: "Partners",
                fields: {
                    "data.href": "Link",
                    "main": "Is main",
                    "picture[0].src": "Picture"
                }
            },
            "files": {
                name: "Files",
                fields: {
                    "filename": "Name",
                    "description": "Description",
                    "url": "Link to resource"
                }
            }
        },

        "current-lang": "Content language",
        "create": "Create",
        "edit": "Edit",
        "delete": "Delete",
        "translate": "Translate",
        "auto-translate-label": "auto translated text",
        "clear": "Clear",
        "translating-error": "Translating error",
        "content-sources-active": "News source enabled",
        "content-sources-inactive": "News source disabled",
        "request-errror": "Request execution error",
        "enable": "Enable",
        "disable": "Disable",

        "tabs": {
            "base": "Main info",
            "blocks": "Blocks per page",
            "header": "Header",
            "footer": "Footer",
            "connects": "Connects",
            "map": "Map settings",
            "rssList": "List settings",
            "rssSettings": "Rss settings",
            "parserSettings": "Parser settings",
        },

        "common-fields": {
            "name": "Name",
            "title": "Title",
            "heading-type": "Heading type",
            "heading": "Title",
            "teaser": "Teaser",
            "description": "Description",
            "original-lang": "Original language",
            "slug": "Slug",
            "sort": "Sort order",
            "link": "Link to page",
            "picture": "Picture",
            "published_at": "Published data",
            "country": "Country",
            "category": "Category",
            "news-type": "News type",
            "content-type": "Content type",
            "position": "Position",
            "simple-news": "Simple news",
            "base-news": "Base news",
            "fixed-news": "Fixed news",
            "email": "Email",
            "news": "News",
            "project": "Project",
            "cron": "Cron",
            "cron.hour": "Each hour",
            "cron.day": "Everyday",
            "cron.week": "Every week",
            "sourceUrl": "Link to resource",
            "sourceRss": "Link to rss",
            "source.name": "Source name",
            "source.fullName": "Full source name",
            "data.listSource": "Url of news page",
            "data.publichedxpath": "Xpath of publication date",
            "data.newsxpath": "Xpath urla news",
            "fullText": "Main text",
            "originalLink": "Link to original",
            "item": "Block of one article",
            "contentTitle": "Block of one article",
            "contentDescription": "Content description",
            "contentPicture": "Content Image",
            "contentPictureGallery": "Image gallery",
            "url": "Link to resource"
        },
        
        "picture": {
            "name": "Picture",
            "device": "Device",
            "title": "Title",
            "alt": "Alternative text",
            "crop": "Crop",
            "set-original": "Leave the original",
            "download": "Download",
            "loading": "Image on save process"
        },

        "section": {
            "type": "Section component",
            "map": {
                "name": "Map",
                "api-key": "Map api-key"
            },
            "about": {
                "body": "Content"
            },
            "content": {
                "name": "Content",
                "type": "Content type",
                "heading-type": "Heading type",
                "title": "Contents title",
                "subtitle": "Contents subtitle",
                "body": "Contents body",
                "link-to-video": "Link to video",
                "link-to-vk-post": "Link to vk post",
                "img": "Attach an image",
                "accordion": "Add accordion element",
                "file": "Attach a file",
                "file-desc": "File signature",
                "btns": "Buttons",
                "btn-label": "Button label"
            },
            "image-gallery": {
                "name": "Gallery",
                "title": "Title",
                "thumbnail": "Thumbnail"
            },
            "about-persons": {
                "add-person": "Add person",
            }
        }
    },
};

export default messages;
