import React from "react";
import { useLocale, useTranslate, BulkDeleteButton } from "react-admin";

import { ListElements } from "../../Components/Containers";

export const ListView = (props) => {
    const locale = useLocale();
    const translate = useTranslate();

    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            label: translate("common-fields.name"),
            source: `name[${locale}].auto` || "name.ru.auto" ,
        },
        {
            type: "TextField",
            source: "slug",
        },
        {
            type: "TextField",
            source: "code",
        },
    ];

    const BulkActionButtons = ({ basePath }) => <BulkDeleteButton basePath={basePath} />;

    return <ListElements {...props} fields={fields} BulkActionButtons={BulkActionButtons} />;
};
