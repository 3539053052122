/* eslint-disable  */
import React from "react";
import {
    useCreateController,
    CreateContextProvider,
    useDataProvider,
    useTranslate,
    useLocale,
} from "react-admin";
import { useHistory } from "react-router-dom";
import {
    Grid,
    Select,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    InputLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import { useTranslate as useTranslateWidget } from "../../widgets";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
}));

export function CreateApi(props) {
    const classes = useStyles();
    const createControllerProps = useCreateController(props);
    const dataProvider = useDataProvider();
    const { handleChangeLocale, handleSetTranslateState, translateState } = useTranslateWidget();
    let history = useHistory();
    const translate = useTranslate();
    const locale = useLocale();

    const {
        basePath,
        record,
        redirect,
        resource,
        saving,
        version,
    } = createControllerProps;

    async function save(formData) {
        const { transform } = props;

        let transformedData = {};

        if (transform) {
            transformedData = await transform(formData);
        }

        const data = transform ? transformedData : formData;

        dataProvider
            .create(resource, { data })
            .then((response) => {
                if (props.callback) {
                    callback(response);
                }
                history.push(`/${resource}`);
            })
            .catch((error) => {
                //TODO: logout user here if 401
                console.error("Ошибка создания: ", { error })
            });
    }

    function onLocalesChecked(event) {
        handleSetTranslateState({
            ...translateState,
            [event.target.name]: event.target.checked,
        });
    }

    function onLocaleChange(event, child) {
        handleChangeLocale(child.props.value);
    }

    const localesSelectMap = [
        { code: "ru", title: { ru: "Русский", en: "Russian" } },
        { code: "en", title: { ru: "Английский", en: "English" } },
        { code: "el", title: { ru: "Греческий", en: "Greece" } },
        { code: "sr", title: { ru: "Сербский", en: "Serbia" } },
        { code: "fr", title: { ru: "Французский", en: "France" } },
        { code: "ge", title: { ru: "Грузинский", en: "Georgia" } },
        { code: "al", title: { ru: "Албанский", en: "Albania" } },
        { code: "ae", title: { ru: "Арабский", en: "UAE" } },
        { code: "he", title: { ru: "Иврит", en: "Israel" } },
        { code: "tr", title: { ru: "Турецкий", en: "Turkey" } },
        { code: "cn", title: { ru: "Китайский", en: "China" } },
        { code: "ja", title: { ru: "Японский", en: "Japan" } },
    ];

    return (
            <CreateContextProvider value={createControllerProps}>
                <div>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <Grid item xs={6}>
                            <h1>{translate("create")}</h1>
                        </Grid>
                        {
                            props.needTranslate && (
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                    >
                                        <InputLabel id="demo-simple-select-label">
                                            {translate("current-lang")}
                                        </InputLabel>
                                        <Select
                                            label={translate("current-lang")}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue={"ru"}
                                            onChange={(e, v) => onLocaleChange(e, v)}
                                        >
                                            {localesSelectMap.map(({ code, title }) => (
                                                <MenuItem value={code}>
                                                    {title[locale]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }
                    </Grid>
                    {
                        props.needTranslate && (
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                        >
                            <FormGroup row>
                                {localesSelectMap.map(({ code, title }, idx) => {
                                    return (
                                        <FormControlLabel
                                            key={idx}
                                            control={
                                                <Checkbox
                                                    checked={translateState[code]}
                                                    onChange={onLocalesChecked}
                                                    name={code}
                                                />
                                            }
                                            label={title[locale]}
                                            labelPlacement="top"
                                        />
                                    );
                                })}
                            </FormGroup>
                        </Grid>
                        )
                    }
                    {React.cloneElement(props.children, {
                        basePath,
                        record,
                        redirect,
                        resource,
                        save,
                        saving,
                        version,
                    })}
                </div>
            </CreateContextProvider>
    );
};

CreateApi.propTypes = {
    transform: PropTypes.func,
    needTranslate: PropTypes.bool,
    children: PropTypes.node,
};

CreateApi.defaultProps = {
    transform: undefined,
    needTranslate: true,
};