import React from "react";
import { BulkDeleteButton } from "react-admin";
import moment from "moment";

import { ListElements } from "../../Components/Containers";

export const ListView = (props) => {
    const fields = [
        {
            type: "TextField",
            label: "id",
            source: "id",
        },
        {
            type: "TextField",
            source: "email",
        },
        {
            type: "FunctionField",
            render: record => <span>{moment(record.created_at).format("DD.MM.YYYY HH:24")}</span>,
            source: "created_at",
        },
    ];

    const BulkActionButtons = ({ basePath }) => <BulkDeleteButton basePath={basePath} />;

    return <ListElements {...props} fields={fields} BulkActionButtons={BulkActionButtons} />;
};
