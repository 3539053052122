import { fetchUtils }  from "react-admin";
import hasuraProvider from "ra-data-hasura";

function httpClient(url, options) {
    if (!options.headers) {
        options.headers = new Headers({ Accept: "application/json" });
    }

    const token = localStorage.getItem("token");

    if (token) {
        options.headers.set("Authorization", `Bearer ${token}`);
    } else {
        console.error("Token not found");
    }

    return fetchUtils.fetchJson(url, options);
}

export default hasuraProvider(
    process.env.REACT_APP_GRAPHQL_ENDPOINT,
    httpClient
);
