import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, useSetLocale, useLocale } from "react-admin";
import { Typography, Select, Button } from "@material-ui/core";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    formControl: {
        opacity: "0",
        position: "absolute",
        top: 0,
        right: 0
    },
    button: {
        display: 'block',
    },
}));

export default function CustomAppBar(props) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const setLocale = useSetLocale();
    const locale = useLocale();

    function handleChange(event) {
        setLocale(event.target.value);
    }

    function handleOpen() {
        setOpen(true);
    }

    const localesMap = {
        "ru": "Русский",
        "en": "Eglish"
    }

    return (
        <>
            <AppBar {...props}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                        justifyContent: "space-between"
                    }}
                >
                    <Typography
                        id="react-admin-title"
                        variant="subtitle1"
                        color="inherit"
                    />
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative"
                    }}>
                        <Button className={classes.button} onClick={handleOpen}>
                            {localesMap[locale]}
                        </Button>

                        <FormControl className={classes.formControl}>
                            <Select
                                open={open}
                                onClose={() => setOpen(false)}
                                onOpen={() => setOpen(true)}
                                value={locale}
                                onChange={handleChange}
                            >
                                <MenuItem value={"ru"}>Русский</MenuItem>
                                <MenuItem value={"en"}>Eglish</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </AppBar>
        </>
    );
}